app.service('GanttService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getTasks = function (model, portletId) {
            return basePluginService.backend.post("ganttTasks", model, portletId, true);
        };

        service.getDependencies = function (model, portletId) {
            return basePluginService.backend.post("ganttDependencies", model, portletId, true);
        };

        service.getResources = function (model, portletId) {
            return basePluginService.backend.post("ganttResources", model, portletId, true);
        };

        service.getAssignments = function (model, portletId) {
            return basePluginService.backend.post("ganttAssingments", model, portletId, true);
        };


        return service;

    }]);