app.controller('FlowApplicationOverview_CreateOrEditPortletController',
    [
        '$scope',
        '$uibModalInstance',
        'createOrEditModalService',
        'FlowApplicationOverviewConfigurationService',
        'data',
        'FlowApplicationOverviewService',
        'Guid',
        'kendoGridUtilService', 'dataEvalService', 'editorService',
        function($scope,
            $uibModalInstance,
            createOrEditModalService,
            FlowApplicationOverviewConfigurationService,
            data,
            FlowApplicationOverviewService,
            Guid,
            kendoGridUtilService,
            dataEvalService,
            editorService) {

            $scope.editorService = editorService;

            $scope.panelBarOptions = {
                expandMode: "single",
                select: $scope.expandCollapse
            };

            $scope.FlowApplicationOverviewService = FlowApplicationOverviewService;
            $scope.model = createOrEditModalService.getModel("FlowApplicationOverview", data);
            $scope.configurationModel =
                FlowApplicationOverviewConfigurationService.getConfigurationModel($scope.model.Properties);


            $scope.onclickUserActions = function(dataItem) {

                if (dataItem.UserActions) {
                    dataItem.UserActions = false;
                } else {
                    dataItem.UserActions = true;
                }

                angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                    function(rowObject) {
                        if (rowObject.Id == dataItem.Id) {
                            rowObject.UserActions = dataItem.UserActions;
                        }
                    });
            }
	        $scope.getSortComboBoxOptions = function () {
		        var datasource = new kendo.data.DataSource({
			        serverFiltering: true,
			        transport: {
						read: function (opts) {
							
					        var paginationModel = {};
							paginationModel.PortletId = $scope.portlet.Id;
							paginationModel.ApplicationIdentifier = $scope.configurationModel.Application.Id;

							FlowApplicationOverviewService
								.getApplicationHeader($scope.PaginationModel, $scope.portlet.Id)
								.then(function(result) {
									$scope.controllingModel.DisplayLoadingBar = false;
									$scope.headerResult = result;

									if (result.data.Headers.length > 0) {
										opts.success(response.data.Headers);
									}
								}).catch(function(error) {
									opts.error(error);
								});
						}
			        }
		        });

		        return {
			        filter: "contains",
			        dataTextField: "ColumnDescription",
			        dataValueField: "ColumnCode",
			        valuePrimitive: true,
			        dataSource: datasource,
			        open: function () {
				        this.dataSource.data([]);
				        this.dataSource.read();
			        }
		        };

	        };

            $scope.colorGrid = {
                editable: true,
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    {
                        field: "Position",
                        title: "Pos",
                        width: 24,
                        readonly: true,
                        editor: kendoGridUtilService
                            .getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'),
                        template:
                            '<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>'
                    },
                    {
                        field: "Colour",
                        title: "Colour",
                        width: "50px",
                        editor: kendoGridUtilService.getColorPicker(),
                        template: kendoGridUtilService.getColorTemplate()
                    },
                    { field: "FirstValue", title: "First Value", width: "150px" },
                    {
                        field: "Func",
                        title: "Func",
                        width: "100px",
                        editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                        template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                    },
                    { field: "SecondValue", title: "Second Value", width: "150px" },
                    {
                        command: [
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 24
                    }
                ],
                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.colorRulesBackground,
                    autoSync: true,
                    transport: {
                        read: function(o) {
                            o.success($scope.configurationModel.colorRulesBackground);
                        },
                        create: function(o) {
                            var item = o.data;
                            item.Position = $scope.configurationModel.colorRulesBackground.length + 1;
                            $scope.configurationModel.colorRulesBackground.unshift(item);
                            o.success(item);
                        },
                        update: function(o) {
                            o.success();
                        },
                        destroy: function(o) {
                            for (var index = 0;
                                index < $scope.configurationModel.colorRulesBackground.length;
                                index++) {
                                if ($scope.configurationModel.colorRulesBackground[index].Id == o.data.Id) {
                                    $scope.configurationModel.colorRulesBackground.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        },
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                Position: { type: "int", defaultValue: 0 },
                                FirstValue: { type: "string" },
                                Func: { type: "string", defaultValue: 1 },
                                SecondValue: { type: "string" },
                                Colour: {
                                    type: "string",
                                    defaultValue: "#000000",
                                    validation: {
                                        required: { message: "Field is required"}
                                    }
                                }

                            }
                        },
                    }
                })
            };

            $scope.colorGrid.dataSource.fetch(function() {
                $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
            });

            $scope.onRuleDrop = function(e) {

                var id = $(e.draggable.element).attr('id').split('_')[1];

                var target = $scope.colorGrid.dataSource.get(id),
                    dest = $(e.dropTarget);

                var dropTargetId = $(e.dropTarget).attr('id').split('_')[1];

                if (dest.is("th")) {
                    return;
                }
                dest = $scope.colorGrid.dataSource.get(dropTargetId);

                //not on same item
                if (target.get("id") !== dest.get("id")) {
                    //reorder the items
                    var tmp = target.get("Position");
                    target.set("Position", dest.get("Position"));
                    dest.set("Position", tmp);

                    $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
                }
            }


            $scope.panelBarOptions = {
                expandMode: "single"
            };


            $scope.createEditorDropDownList = function(container, options) {

                $('<input required data-bind="value:' + options.field + '"/>')
                    .appendTo(container)
                    .kendoComboBox({
                        filter: "contains",
                        autoBind: true,
                        dataSource: [
                            "Text", "Numeric", "Decimal", "Date", "Time", "DateTime"
                        ]

                    });
            };

            $scope.onclickHidden = function(dataItem) {
                if (dataItem.IsHidden) {
                    dataItem.IsHidden = false;
                } else {
                    dataItem.IsHidden = true;
                }
                angular.forEach($scope.configurationModel.Mappers,
                    function(rowObject) {
                        if (rowObject.Id === dataItem.Id) {
                            rowObject.IsHidden = dataItem.IsHidden;
                        }
                    });
            }

            $scope.onAppSelected = function() {

                $scope.configurationModel.GroupingModel = {};
                $scope.configurationModel.DefaultSortingModel = {};
                $scope.groupCombo.dataSource.read();
                $scope.sortCombo.dataSource.read();
                $scope.mapQueryColumns();
            };


            $scope.removeAllMappers = function() {
                var grid = $("#mapperGrid").data("kendoGrid");
                $scope.configurationModel.Mappers = [];
                grid.dataSource.data([]);
            }
            $scope.mapQueryColumns = function() {

                var paginationModel = {

                    //ApplicationIdentifier : $scope.configurationModel.ApplicationIdentifier,  
                    ApplicationIdentifier: $scope.configurationModel.Application.Id,
                    PageNumber: 0,
                    PageSize: 100
                };

                FlowApplicationOverviewService.getApplicationHeader(paginationModel)
                    .then(function(response) {
                        $scope.queryExecutionFailure = false;
                        var grid = $("#mapperGrid").data("kendoGrid");
                        $scope.removeAllMappers();

                        var headers = response.data.Headers;
                        var i, arrLen = headers.length;

                        for (i = 0; i < arrLen; i++) {
                            grid.dataSource.add({
                                DatabaseColumn: headers[i].ColumnCode,
                                ColumnName: headers[i].ColumnDescription,
                                Editor: "Text"
                            });
                        }
                    }).catch(
                        function(error) {
                            $scope.queryExecutionFailure = true;
                            $scope.failureReason = error.data.ResponseStatus.Message;
                        });
            }

            $scope.mapperOptions = {
                editable: true,
                scrollable: false,
                toolbar: [
                    {
                        name: "mapQueryColumns",
                        template: '<button class="k-button" ng-click="mapQueryColumns()">Refresh Columns</button>'
                    },
                    {
                        name: "errInfo",
                        template:
                            '<span ng-show="queryExecutionFailure" tooltip-placement="top" uib-tooltip="{{failureReason}}" style="margin-left:10px; font-size:12px;" class="label label-danger">There is a problem with mapping!</span>'
                    }
                ],
                columns: [
                    { field: "DatabaseColumn", title: "Database column", width: "200px" },
                    { field: "ColumnName", title: "Column name", width: "210px" },
                    { field: "Width", title: "Width", width: "90px" },
                    { field: "Editor", title: "Editor", width: "200px", editor: $scope.createEditorDropDownList },
                    { field: "Length", title: "Length", width: "100px" },
                    {
                        field: "IsHidden",
                        title: "Hidden",
                        width: "70px",
                        template:
                            "<input ng-click=onclickHidden(dataItem) type='checkbox' #= IsHidden ? checked='checked' : '' # ></input>"
                    }
                ],

                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.Mappers,
                    autoSync: true,
                    transport: {
                        read: function(o) {
                            angular.forEach($scope.configurationModel.Mappers,
                                function(mapper) {
                                    if (!mapper.Editor) {
                                        mapper.Editor = "Text";
                                    }
                                });
                            o.success($scope.configurationModel.Mappers);
                        },

                        create: function(o) {
                            var item = o.data;
                            item.Id = $scope.configurationModel.Mappers.length + 1;
                            item.IsEditable = true;
                            item.Code = Guid.newGuid();
                            $scope.configurationModel.Mappers.unshift(item);
                            o.success(item);
                        },

                        update: function(o) {
                            o.success();
                        },
                        destroy: function(o) {
                            for (var index = 0; index < $scope.configurationModel.Mappers.length; index++) {
                                if ($scope.configurationModel.Mappers[index].Id == o.data.Id) {
                                    $scope.configurationModel.Mappers.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        },
                    },
                    schema: {
                        model: {
                            id: "Id",
                            fields: {
                                DatabaseColumn: { type: "string" },
                                ColumnName: { type: "string" },
                                Width: { type: "string" },
                                Editor: { defaultValue: "Text" },
                                Length: { type: "string" },
                                IsHidden: { type: "boolean" }
                            }
                        }
                    }
                })
            };

            $scope.isCustomWorkflowsGridInEditMode = function() {
                var inEditMode = $('#customWorkflowsGrid').find('.k-grid-edit-row').length > 0 ? true : false;
                if (inEditMode) {
                    return true;
                };
                return false;
            }

            $scope.flowOptions = {
                editable: "inline",
                scrollable: false,
                edit: function(e) {
                    //change default action buttons
                    var commandCell = e.container.find("td:last");
                    commandCell.html(
                        "<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
                        "<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
                    );
                },
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    { field: "DisplayName", title: "Display Name", width: "150px" },
                    {
                        field: "UserActions",
                        title: "User actions",
                        width: "70px",
                        template: "<input type='checkbox' #= UserActions ? checked='checked' : '' # ></input>",
                        editor: kendoGridUtilService.makeKendoCheckboxFromValue("UserActions"),
                    },
                    {
                        field: "CustomFlow",
                        title: "Custom Flow",
                        width: "200px",
                        editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
                        template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
                    },
                    {
                        field: "DatasetName",
                        title: "Workflow input table",
                        width: "150px"
                    },
                    {
                        field: "AlwaysEnabled",
                        title: "Always Enabled",
                        width: "70px",
                        template: "<input type='checkbox' #= AlwaysEnabled ? checked='checked' : '' # ></input>",
                        editor: kendoGridUtilService.makeKendoCheckboxFromValue("AlwaysEnabled")
                    },
                    { field: "LeftValue", title: "Left Value", width: "150px" },
                    {
                        field: "Func",
                        title: "Func",
                        width: "100px",
                        editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                        template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                    },
                    { field: "RightValue", title: "Right Value", width: "150px" },
                    {
                        command: [
                            {
                                name: "edit",
                                template:
                                    "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
                            },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 24
                    }
                ],

                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.WorkFlowModel.FlowConfigurations,
                    autoSync: false,
                    transport: {
                        read: function(o) {
                            o.success($scope.configurationModel.WorkFlowModel.FlowConfigurations);
                        },
                        create: function(o) {
                            var item = o.data;
                            item.Id = $scope.configurationModel.WorkFlowModel.FlowConfigurations + 1;
                            item.Code = Guid.newGuid();
                            $scope.configurationModel.WorkFlowModel.FlowConfigurations.unshift(item);
                            o.success(item);
                        },
                        update: function(o) {
                            o.success();
                        },
                        destroy: function(o) {
                            for (var index = 0;
                                index < $scope.configurationModel.WorkFlowModel.FlowConfigurations.length;
                                index++) {
                                if ($scope.configurationModel.WorkFlowModel.FlowConfigurations[index].Id == o.data.Id) {
                                    $scope.configurationModel.WorkFlowModel.FlowConfigurations.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        }
                    },
                    schema: {
                        model: {
                            id: "Id",
                            fields: {
                                DisplayName: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"}
                                    }
                                },
                                UserActions: {
                                    type: "boolean"
                                },
                                CustomFlow: {
                                    validation: {
                                        required: true,
                                        //Proper validation to be finished - luczpl
                                        customFlowValidation: function(input) {
                                            return true;
                                        }
                                    }
                                },
                                AlwaysEnabled: {
                                    type: "boolean",
                                },
                                LeftValue: { type: "string" },
                                Func: { type: "string", defaultValue: "1" },
                                RightValue: { type: "string" },
                                DatasetName: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"}
                                    }
                                }
                            }
                        }
                    }
                })
            };
            $scope.onclickAlwaysEnabled = function(dataItem) {

                if (dataItem.AlwaysEnabled) {
                    dataItem.AlwaysEnabled = false;
                } else {
                    dataItem.AlwaysEnabled = true;
                }
                angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                    function(rowObject) {
                        if (rowObject.Id === dataItem.Id) {
                            rowObject.AlwaysEnabled = dataItem.AlwaysEnabled;
                        }
                    });
            };


            $scope.ok = function() {
                $scope.mapperOptions.dataSource.sync();
                $scope.flowOptions.dataSource.sync();
                $scope.customWorkflowsGrid.dataSource.sync();

                $scope.configurationModel.WorkFlowModel.FlowConfigurations = [];

                var workflowConfigurations = $scope.customWorkflowsGrid.dataSource.data();
                for (var p = 0; p < workflowConfigurations.length; p++) {
                    $scope.configurationModel.WorkFlowModel.FlowConfigurations.push(workflowConfigurations[p]);
                }

                $scope.configurationModel.Mappers = [];

                var mapperOptions = $scope.mapperOptions.dataSource.data().toJSON();
                for (var i = 0; i < mapperOptions.length; i++) {
                    if (mapperOptions[i].DatabaseColumn) {
                        mapperOptions[i].Id = i + 1;
                        $scope.configurationModel.Mappers.push(mapperOptions[i]);
                    }
                }


                FlowApplicationOverviewConfigurationService.saveProperties($scope.model.Properties,
                    $scope.configurationModel);
                $uibModalInstance.close($scope.model);
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };


        }
    ]);
