app.controller("BaseTableController",
	[
		"$scope", "$rootScope", "$uibModal", "tableConfigurationService",
		"receiverService", "editorService", "portletErrorService", "localStorageService", "translationsService",
		"dataEvalService", "globalVariableService", "$compile", "sharedSessionService", "appConfig", "$controller",
		"$timeout", "kendoGridUtilService", "TableService", "translationFlowClientService", "$q", "cascadeLovService",
		function ($scope,
			$rootScope,
			$uibModal,
			tableConfigurationService,
			receiverService,
			editorService,
			portletErrorService,
			localStorageService,
			translationsService,
			dataEvalService,
			globalVariableService,
			$compile,
			sharedSessionService,
			appConfig,
			$controller,
			$timeout,
			kendoGridUtilService,
			TableService,
			translationFlowClientService,
            $q,
            cascadeLovService
		) {

			$scope.kendoGridUtilService = kendoGridUtilService;
			$scope.backslashPlaceHolder = "%5C";

			var timer;

			$scope.refresh = function () {
				$scope.PaginationModel.RefreshWorkflowCache = true;
				$scope.refreshSilently();
				$rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
			};

			$scope.$on("onMaximized",
				function (event, pcPortlets) {
					var currentPortletsInMaximizedPc = pcPortlets.filter(function (p) {
						return p.Id === $scope.portlet.Id;
					});

					if (currentPortletsInMaximizedPc.length > 0) {
						$scope.createColumns($scope.headerResult.data.Headers);
					}
				});

			$scope.$on("onMinimized",
				function (event, pcPortlets) {

					var currentPortletInMinimizedPc = pcPortlets.filter(function (p) {
						return p.Id === $scope.portlet.Id;
					});

					if (currentPortletInMinimizedPc.length > 0) {
						$scope.createColumns($scope.headerResult.data.Headers);
					}
				});

			$scope.$on("portletTabChanged",
				function (event, data) {
					$scope.moveElementsWhenScrollVisible();
				});

			$scope.$on("preventDataLost",
				function (event, data) {
					$scope.shouldWarnAboutDataToLose().then(function(shouldContinue) {
						$scope.openUnsavedChangesModal();
					}).catch(function() {});
				});

			$scope.gridIsEditable = function () {
				if ($scope.configurationModel.ReadOnly) {
					return false;
				}
				return true;
			};

			$scope.canDelete = function () {
				var workflowObj = $scope.configurationModel.WorkFlowModel.CrudWorkflow;
				return ($scope.hasWorkflow(workflowObj) && workflowObj.CanDelete) ? true : false;
			};


			$scope.broadcastRowSelected = function (currentDataItem) {
				/* Broadcast selected row, if not multiSelect is on - since the receivers dont support multiline selection*/
				if ($scope.configurationModel.MultiSelect) {
					if ($scope.selectionModel.selectedRows === undefined ||
						$scope.selectionModel.selectedRows.length > 1) {
						currentDataItem = {};
					}
				}
				cascadeLovService.setCurrentCascadeObject($scope.portlet.Id, currentDataItem);
				$rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: currentDataItem });
			};


			$scope.$on(
				"$destroy",
				function () {
					$timeout.cancel(timer);
				}
			);

			$scope.$watch(function () {
				return $scope.selectionModel.selectedRows.length;
			},
				function () {
					$scope.selectionModel.emptySelection = $scope.selectionModel.selectedRows.length === 0;
				});

			$scope.onChange = function (arg) {

				/* Arrange a complete selection for the selection model - used by among other things custom flows*/
				var fullSelection = arg.sender.select();
				var currentDataItems = [];
				angular.forEach(fullSelection,
					function (fullRowObject) {
						currentDataItems.push(arg.sender.dataItem(fullRowObject));
					});

				$scope.selectionModel.selectedRows = currentDataItems;
				timer = $timeout(function () {
					$scope.$digest();
				});

				$scope.broadcastRowSelected(arg.sender.dataItem(this.select()));
			};

			$scope.$on("resize",
				function (event, data) {
					if (data) {
						for (var i = 0; i < data.PortletIds.length; i++) {
							if (data.PortletIds[i] === $scope.portlet.Id) {
								$scope.gridOptions = $scope.getTableOptions($scope.headerResult);
								$scope.moveElementsWhenScrollVisible();
							}
						}
					} else {
						if ($scope.headerResult) {
							$scope.gridOptions = $scope.getTableOptions($scope.headerResult);
							$scope.$apply();
							$scope.moveElementsWhenScrollVisible();
						}
					}

				});

			//dataBinding event handler
			//use it to restrict the Grid to have only one column grouped
			$scope.onDataBinding = function (e) {

				if ($scope.tableGrid) {

					if ($scope.portlet.PortletType === 'Table' && !$scope.tableGrid.pager.element.find("#clear-user-settings-button" + $scope.portlet.Id).length) {
						$scope.tableGrid.pager.element.find("a.k-pager-refresh.k-link")
							.after(
								"<a title='" + translationFlowClientService.get("ClearUserSettings", "Clear user settings") + "' class='k-pager-clear k-link' href='#' ng-click='clearUserSettings();$event.preventDefault();' style='float:right' id='clear-user-settings-button" +
								$scope.portlet.Id +
								"'><span class='k-icon k-i-clear-css'></span></a>");
						$compile($("#clear-user-settings-button" + $scope.portlet.Id))($scope);
					}

					if (!$scope.tableGrid.pager.element.find("#clear-filters-button" + $scope.portlet.Id).length) {
						$scope.tableGrid.pager.element.find("a.k-pager-refresh.k-link")
							.after(
								"<a class='k-pager-clear k-link' href='#' ng-click='clearFilters();$event.preventDefault();' style='float:right' id='clear-filters-button" +
								$scope.portlet.Id +
								"'><span class='k-icon k-i-filter-clear'></span></a>");
						$compile($("#clear-filters-button" + $scope.portlet.Id))($scope);
					}
					var gr = e.sender.dataSource.group();

					if (gr.length > 1) {
						gr.shift();
						e.sender.dataSource.fetch();
					}

					if (gr.length === 0) {
						localStorageService.remove($scope.portlet.Id, sharedSessionService.getUser(), "group");
					} else {
						var slimGroup = { field: gr[0].field };
						localStorageService.save($scope.portlet.Id, sharedSessionService.getUser(), "group", slimGroup);
					}

					var filter = e.sender.dataSource.filter();

					localStorageService.save($scope.portlet.Id,
						sharedSessionService.getUser(),
						"sort",
						e.sender.dataSource.sort());
					localStorageService.save($scope.portlet.Id,
						sharedSessionService.getUser(),
						"filter",
						e.sender.dataSource.filter());


					this.thead.find(".k-header-column-menu.k-state-active").removeClass("k-state-active");
					if (filter) {
						var filteredMembers = {};
						$scope.highlightColumn(filter, filteredMembers);
						this.thead.find("th[data-field]")
							.each(function () {
								var cell = $(this);
								var filtered = filteredMembers[cell.data("field")];
								if (filtered) {
									cell.find(".k-header-column-menu").addClass("k-state-active");
								}
							});
					}
					$("#portletContentDiv-" + $scope.portlet.Id + " .k-pager-refresh")
						.click(function () {
							$scope.PaginationModel.RefreshWorkflowCache = true;
							$rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
						});
				}
			};

			$scope.shouldManuallyRefresh = function () {

				var hasDataToLost = $scope.preventLostData.getForPortlet($scope.portlet.Id);
				var shouldManuallyRefresh = $q(function (resolve, reject) {
					if (hasDataToLost) {
						resolve($scope.continue);
					} else {
						reject("Manual refresh not allowed");
					}
				});
				return shouldManuallyRefresh;
			};

			$scope.shouldWarnAboutDataToLose = function () {

				var hasDataToLost = $scope.preventLostData.getForPortlet($scope.portlet.Id);
				var shouldWarnAboutDataToLose = $q(function (resolve, reject) {
					if (hasDataToLost) {
						resolve(true);
					} else {
						resolve(false);
					}
				});
				return shouldWarnAboutDataToLose;
			};

			$scope.colorDataItems = function (dataItems, tbody) {
				for (j = 0; j < dataItems.length; j++) {

					var color = $scope.calculateColor(dataItems[j]);
					if (color) {
						var row = tbody.find("[data-uid='" + dataItems[j].uid + "']");
						row.css("background-color", color);
					}
				}
			};

			$scope.onDataBound = function (e) {
				$(".k-group-col,.k-group-cell").width(10);

				var columns = e.sender.columns;
				var columnIndex = undefined;
				var i;
				for (i = 0; i < columns.length; i++) {
					if (columns[i].command) {
						columnIndex = i;
						break;
					}
				}

				if ($scope.configurationModel.Groupable) {
					columnIndex = columnIndex + 1;
				}

				// iterate the table rows and apply custom row and cell styling
				var rows = e.sender.tbody.children();

				if (e.sender.dataSource.group().length)
					updateLovGroupHeaders(rows);

				var j;
				for (j = 0; j < rows.length; j++) {
					var row = $(rows[j]);

					row.dblclick(function () {
						$scope.rowDblClick();
					});
				}

				// Apply color
				//  iterate the data items and apply row styles where necessary

				var dataItems = e.sender.dataSource.view();
				if (e.sender.dataSource.group().length > 0)

					//  iterate the groups  and apply row styles where necessary
					for (g = 0; g < dataItems.length; g++) {
						var groupDataItems = dataItems[g].items;
						$scope.colorDataItems(groupDataItems, e.sender.tbody);
					}
				else {
					$scope.colorDataItems(dataItems, e.sender.tbody);
				}

				if (!$scope.selectionModel.emptySelection) {
					for (i = 0; i < $scope.selectionModel.selectedRows.length; i++) {
						//check length of current, and previously selected item (uid changes in time, rnum is not unique)NCRND-8983
						var prevRuid = JSON.stringify($scope.selectionModel.selectedRows[i]);

						for (j = 0; j < dataItems.length; j++) {
							var currRuid = JSON.stringify(dataItems[j]);

							if (prevRuid === currRuid) {
								var select = e.sender.tbody.find('tr[data-uid="' + dataItems[j].uid + '"]');
								e.sender.select(select);
							}
						}
					}
				}

				if ($scope.configurationModel.EditableForNewRowsOnly) {
					for (j = 0; j < dataItems.length; j++) {
						if (dataItems[j].rnum !== "") {
							e.sender.tbody.find('tr[data-uid="' + dataItems[j].uid + '"]').find('.k-grid-delete').hide();
						}

					}
				}
				//Kendo advices do a hack: https://www.telerik.com/forums/tooltip-for-clear-filter-icon 
				jQuery(".k-icon.k-i-filter-clear").attr("title", kendo.ui.FilterCell.prototype.options.messages.clear);

				$scope.moveElementsWhenScrollVisible();

			};

			function updateLovGroupHeaders(rows) {

				var currentGroupHeaderLovEl = rows.find("span[data-grid-group-header-lov-name]")[0];
				if (!currentGroupHeaderLovEl)
					return;
				var currentGroupHeaderLovName = currentGroupHeaderLovEl.getAttribute("data-grid-group-header-lov-name");

				var grpHeadersElements = rows.find("span.grp_" + currentGroupHeaderLovName).get();

				if (!grpHeadersElements.length)
					return;

				TableService.readLovQuery({ Code: currentGroupHeaderLovName, RowFilter: {} }, $scope.portlet.Id)
					.then(function (result) {
						var dict = result.data;
						grpHeadersElements.forEach(function (el) {
							var textEl = el.innerText.trim();
							var textObj = dict.filter(function (de) {
								return de.Value === textEl;
							})[0];
							jQuery(el).text(textObj.Text);
						});
					}).catch(function () {});
			}

			$scope.moveElementsWhenScrollVisible = function () {
				timer = $timeout(function () {
					var portletElement = $("#portletContentDiv-" + $scope.portlet.Id);
					var element = portletElement.closest(".portletContainerContent");

					if (element.prop('scrollHeight') !== element.prop('clientHeight')) {
						portletElement.find(".k-pager-wrap").addClass("under-scroll-element");
						portletElement.find(".k-header-column-menu").last().addClass("under-scroll-element");
					} else {
						portletElement.find(".k-pager-wrap").removeClass("under-scroll-element");
						portletElement.find(".k-header-column-menu").last().removeClass("under-scroll-element");
					}
				});
			}

			$scope.clearFilters = function () {
				$scope.tableGrid.dataSource.filter([]);
			};

			$scope.clearUserSettings = function () {
				localStorageService.clear($scope.portlet.Id, sharedSessionService.getUser());
				$rootScope.$broadcast("onRecreatePortlet", { PortletId: $scope.portlet.Id });
			};

			$scope.createColumns = function (columns) {

				var sortedColumns = [];

				var columnsInLocalStorage = localStorageService.get($scope.portlet.Id, sharedSessionService.getUser(), "columns");

				if (columnsInLocalStorage) {
					angular.forEach(columnsInLocalStorage,
						function (x) {
							angular.forEach(columns,
								function (y) {
									if (x.field === y.ColumnCode) {
										y.Width = x.width;
										y.IsHidden = x.hidden;

										angular.forEach($scope.configurationModel.Mappers,
											function (z) {
												if (z.DatabaseColumn === y.ColumnCode) {
													y.Menu = !z.IsHidden;
												}
											});

										sortedColumns.push(y);
									}
								});
						});
				} else {
					sortedColumns = columns;

					angular.forEach(sortedColumns, function (x) {

						x.Width = undefined;
						x.IsHidden = undefined;

						angular.forEach($scope.configurationModel.Mappers,
							function (y) {
								if (y.DatabaseColumn === x.ColumnCode) {
									if (y.Width !== undefined && y.Width !== "") {
										x.Width = parseInt(y.Width);
										x.IsHidden = false;
									}
									if (y.IsHidden) {
										x.IsHidden = y.IsHidden;
										x.Width = 0;
										x.Menu = !y.IsHidden;
									}
								}
							});
					});
				}


				//sort columns by order from local storage , set metadata and add command buttons
				var cols = [];

				var commands = [];

				var noOfButtons = 0;;
				var commandColWidth = 0;
				if ($scope.hasWorkflow($scope.configurationModel.WorkFlowModel.DblClickWorkflow)) {
					commands.push({
						name: "dblClick",
						template:
							"<a class='k-button k-button-icontext gridButton' ng-click='rowDblClickFromButton($event)'><span class='k-icon k-i-custom'></span></a>"
					});
					noOfButtons++;
				}

				if ($scope.gridIsEditable() && $scope.canDelete()) {
					commands.push({
						name: "destroy",
						template:
							"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
					});
					noOfButtons++;
				}

				if (noOfButtons === 1)
					commandColWidth = 20;
				else if (noOfButtons > 1)
					commandColWidth = 41;

				if (commands.length) {
					cols.push({
						command: commands,
						width: commandColWidth
					});
				}
				//Data Columns
				if (sortedColumns.length > 0) {

					$scope.width = kendoGridUtilService.getGridWidth($scope.portlet.Id,
						commandColWidth,
						sortedColumns,
						$scope.configurationModel,
						$scope.configurationModel.Groupable);

					var defaultColumnWidth = kendoGridUtilService.getGridColumnDefaultWidth($scope.portlet.Id,
						commandColWidth,
						sortedColumns,
						$scope.configurationModel,
						$scope.configurationModel.Groupable);


					$scope.shouldApplyWidth = kendoGridUtilService.shouldApplyWidth($scope.width, $scope.portlet.Id);

					angular.forEach(sortedColumns, function (x) {
						var col = {};
						$scope.setColumnMetadata(x, col, defaultColumnWidth);
						cols.push(col);
					});
				}

				cols = cols.map(function (c) {
					if (isLovColumn(c)) {
						var lovName = getLovNameFromMappers(c);
						c.groupHeaderTemplate = c.field + ": <span data-grid-group-header-lov-name='" + lovName + "' class='grp_" + lovName + "'>#= value #";
					}
					return c;
				});
				return cols;
			};

			function getLovNameFromMappers(col) {
				var data = $scope.configurationModel.Mappers.filter(function (m) {
					return m.DatabaseColumn === col.field && m.Editor === "Lov";
				})[0];
				if (data)
					return data.Lov;
				return null;
			}

			function isLovColumn(col) {
				var data = $scope.configurationModel.Mappers.filter(function (m) {
					return m.DatabaseColumn === col.field && m.Editor === "Lov";
				});
				return data.length;
			}


			$scope.isEditable = function (columnCode) {

				var isEditable = (_.find($scope.configurationModel.Mappers,
					function (x) { return x.DatabaseColumn === columnCode; })
					|| { IsEditable: false }).IsEditable;

				if (isEditable === undefined) {
					return true;
				}

				if (isEditable)
					return true;
				else {
					return false;
				}
			};

			$scope.calculateColor = function (dataItem) {

				for (var i = 0; i < $scope.orderedColorRulesBackground.length; i++) {
					var currentRule = $scope.orderedColorRulesBackground[i];
					if (dataItem) {
						if ($scope.ruleIsMatching(dataItem, currentRule)) {
							return currentRule.Colour;
						}
					}
				}
				return undefined;
			};

			$scope.ruleIsMatching = function (dataItem, rule) {
				//backward compatible with manually written values
				var firstValue = rule.FirstValue.indexOf("{") > -1 ? rule.FirstValue : "{" + rule.FirstValue + "}";

				return dataEvalService.evaluateExpression(
					dataEvalService.createNewDataSet(dataItem),
					firstValue,
					rule.Func,
					rule.SecondValue);
			};


			$scope.highlightColumn = function setFilteredMembers(filter, members) {
				if (filter.filters) {
					for (var i = 0; i < filter.filters.length; i++) {
						setFilteredMembers(filter.filters[i], members);
					}
				} else {
					members[filter.field] = true;
				}
			};

			$scope.createFilter = function (column, editor, lov) {
				if (editor === "Numeric" || editor === "Decimal") {
					column.filterable = {
						extra: false,
						ui: "numericTextBox"
					};
				}
				if (editor === "Date") {
					column.filterable = {
						extra: true,
						ui: "datepicker"
					};
				} else if (editor === "Time") {
					column.filterable = {
						extra: true,
						ui: "timepicker"
					};
				} else if (editor === "DateTime") {
					column.filterable = {
						extra: true,
						ui: "datetimepicker"
					};
				} else if (editor === "Lov") {
					column.filterable = {
						multi: true,
						search: true,
						dataSource: new kendo.data.DataSource({
							transport: {
								read: function (opts) {
									TableService.readLovQuery({ Code: lov, RowFilter: {} }, $scope.portlet.Id)
										.then(function (result) {
											opts.success(result.data);
										}).catch(function () {});
								}
							}
						}),
						itemTemplate: function (e) {
							if (e.field === "all") {
								//handle the check-all checkbox template
								return "<div><label><strong><input type='checkbox' />&nbsp#=all#</strong></label></div>";
							} else {
								//handle the other checkboxes
								return "<div><label><input type='checkbox' name='" +
									e.field +
									"' value='#=Value#'/><span>&nbsp#=Text#</span></label></div>";
							}
						},
						checkAll: true

					};
				} else {
					column.filterable = {
						extra: false
					};
				}
			};

			$scope.createFields = function (columns) {

				var fields = {};

				angular.forEach(columns, function (x) {

					var editor = (_.find($scope.configurationModel.Mappers,
						function (y) { return y.DatabaseColumn === x.ColumnCode && y.Editor; }) || { Editor: "Text" }).Editor;

					var lov = (_.find($scope.configurationModel.Mappers,
						function (y) { return y.DatabaseColumn === x.ColumnCode && y.Lov; }) || { Lov: "" }).Lov;

					var colType = "string";

					if (lov) {
						colType = "string";
					} else if (editor === "Date" || editor === "Time" || editor === "DateTime") {
						colType = "date";
					} else if (editor === "Numeric") {
						colType = "number";
					} else if (editor === "Decimal") {
						colType = "number";
					} else if (editor === "Checkbox") {
						colType = "boolean";
					}

					fields[x.ColumnCode] = {
						editable: $scope.isEditable(x.ColumnCode),
						type: colType
					};
				});

				return fields;
			};

			$scope.getColumnnHeaderDescription = function (columnCode, columnDescription) {

				var mapper = _.find($scope.configurationModel.Mappers,
					function (x) { return x.DatabaseColumn.toLowerCase() === columnCode.toLowerCase(); })
					|| { ColumnName: columnDescription };

				if (mapper) {
					return translationsService.get(mapper.Code + "-DatabaseColumn", mapper.ColumnName);
				} else {
					return columnDescription;
				}
			};


			$scope.setPaginationSearch = function (paginationModel, options, configurationModel, headerResult) {

				paginationModel.Search = [];

				if (options && options.data.filter && options.data.filter.filters && options.data.filter.filters.length > 0) {
					for (var i = 0; i < options.data.filter.filters.length; i++) {
						var editor;
						var value;
						if (options.data.filter.filters[i].filters) {
							var search = { Logic: options.data.filter.filters[i].logic };
							search.Predicates = [];
							for (var j = 0; j < options.data.filter.filters[i].filters.length; j++) {

								editor = (_.find(configurationModel.Mappers,
									function (x) {
										return x.DatabaseColumn === options.data.filter.filters[i].filters[j].field && x.Editor;
									}) || { Editor: "Text" }).Editor;

								if (editor === "Date" || editor === "Time" || editor === "DateTime") {
									value = kendo.toString(kendo
										.parseDate(options.data.filter.filters[i].filters[j].value,
											appConfig.DEFAULT_DATE_FORMAT),
										appConfig.DEFAULT_DATE_FORMAT);
								} else {
									value = options.data.filter.filters[i].filters[j].value;
								}
								search.Predicates.push({
									Key: options.data.filter.filters[i].filters[j].field,
									Value: value,
									Editor: editor,
									Operator: options.data.filter.filters[i].filters[j].operator,
									NativeType: headerResult.filter(function (x) {
										return options.data.filter.filters[i].filters[j].field.toLowerCase() === x.ColumnCode.toLowerCase();
									})[0].NativeType
								});
							}
							paginationModel.Search.push(search);
						} else {

							editor = (_.find(configurationModel.Mappers, function (x) {
								return x.DatabaseColumn === options.data.filter.filters[i].field && x.Editor;
							}) || { Editor: "Text" }).Editor;

							if (editor === "Date" || editor === "Time" || editor === "DateTime") {
								value = kendo.toString(kendo
									.parseDate(options.data.filter.filters[i].value, appConfig.DEFAULT_DATE_FORMAT),
									appConfig.DEFAULT_DATE_FORMAT);
							} else {
								value = options.data.filter.filters[i].value;
							}
							var headersByColumnCode = headerResult.filter(function(x) {
								return options.data.filter.filters[i].field.toLowerCase() === x.ColumnCode.toLowerCase();
							});

							var nativeType = headersByColumnCode.length ? headersByColumnCode[0].NativeType : null;

							paginationModel.Search.push({
								Key: options.data.filter.filters[i].field,
								Value: value,
								Editor: editor,
								Operator: options.data.filter.filters[i].operator,
								Logic: options.data.filter.filters[i].logic,
								NativeType: nativeType
							});
						}

					}
				}
			};


			$scope.setPaginationGroup = function (paginationModel, options) {

				paginationModel.Group = {};

				if (options && options.data.group && options.data.group.length > 0) {
					paginationModel.Group.Code = options.data.group[0].field;
					paginationModel.Group.Order = options.data.group[0].dir;

				}
			};

			$scope.setPaginationSort = function (paginationModel, options, configurationModel) {

				if (options && options.data.sort && options.data.sort.length > 0) {
					paginationModel.Sort = [];

					for (var i = 0; i < options.data.sort.length; i++) {
						paginationModel.Sort.push({
							Code: options.data.sort[i].field,
							Order :options.data.sort[i].dir
						});
					}

				} else if (configurationModel.DefaultSortingModel.OrderBy &&
					configurationModel.DefaultSortingModel.OrderBy.length > 0) {
					paginationModel.Sort = [];
					var defaultSort = {
						Code : configurationModel.DefaultSortingModel.OrderBy,
						Order : configurationModel.DefaultSortingModel.Direction
					};
					paginationModel.Sort.push(defaultSort);
				} else {
					paginationModel.Sort = [];
				}
			};

            /*
             * per https://stackoverflow.com/a/18403451/2987281
             * @param {} e 
             * @returns {} 
             */
			$scope.checkboxUpdate = function (e) {
				var checked = $(e.currentTarget).prop('checked') === true;
				var grid = $scope.tableGrid;
				grid.closeCell();
				var dataItem = grid.dataItem($(e.currentTarget).closest('tr'));
				var col = $(e.currentTarget).closest('td');
				grid.editCell(col);
				dataItem.set(grid.columns[col.index() - grid._groups()].field, checked);
				grid.closeCell(col);
			};


			$scope.setColumnMetadata = function (dbColumn, col, defaultWidth) {

				//column code
				col.field = dbColumn.ColumnCode;
				col.title = $scope.getColumnnHeaderDescription(dbColumn.ColumnCode, dbColumn.ColumnDescription);
				if (dbColumn.Width === undefined) {
					col.width = defaultWidth;
				} else {
					col.width = dbColumn.Width;
				}

				col.hidden = dbColumn.IsHidden;
				col.menu = dbColumn.Menu;

				//col editor
				var editor = (_.find($scope.configurationModel.Mappers,
					function (x) { return x.DatabaseColumn === dbColumn.ColumnCode && x.Editor; })
					|| { Editor: "Text" }).Editor;

				var lov = (_.find($scope.configurationModel.Mappers,
					function (x) { return x.DatabaseColumn === dbColumn.ColumnCode && x.Lov; })
					|| { Lov: "" }).Lov;

				var isEditable = true;

				if ($scope.configurationModel.ReadOnly) {
					isEditable = false;
				} else {
					isEditable = $scope.isEditable(dbColumn.ColumnCode);
				}

				editorService.applyEditorAndTemplate($scope.portlet.Id,
					editor,
					lov,
					dbColumn.ColumnCode,
					col,
					isEditable);

				$scope.createFilter(col, editor, lov);

				if (editor === "Numeric" || editor === "Decimal") {
					col.attributes = { "class": "numericTableColumn" };
				}

				if (editor === "Checkbox") {
					col.attributes = { "class": "checkboxTableColumn" };
					col.headerAttributes = {
						"class": "checkboxTableColumn"
					};
				}

				if (editor === "Decimal") {

					var length = (_.find($scope.configurationModel.Mappers,
						function (x) { return x.DatabaseColumn === dbColumn.ColumnCode; }) || { Length: null }).Length;

					if (length) {
						col.format = "{0:n" + length + "}";
					}
				}
			};

		}
	]);
