app.controller('BasePluginController',
    [
        '$scope', 'receiverService', 'titleService', 'translationsService', 'urlQueryService', 'featuresService', 'preventLostDataService',
        function ($scope, receiverService, titleService, translationsService, urlQueryService, featuresService, preventLostDataService) {

            if ($scope.portlet) {
                $scope.portlet.titleService = titleService;
            }

            $scope.featuresFlags = {
                hasFeatureFlag: function (featureFlag) {
                    return featuresService.hasFeature(featureFlag);
                }
            };

            $scope.preventLostData = {
                getForPortlet: function (portletId) {
                    return preventLostDataService.getForPortlet(portletId);
                },
                setForPortlet: function (portletId) {
                    preventLostDataService.setForPortlet(portletId);
                },
                unsetForPortlet: function (portletId) {
                    preventLostDataService.unsetForPortlet(portletId);
                }
            };

            $scope.browserUtils = {
                isIPad: function() {
                    var isiPad = navigator.userAgent.match(/iPad/i) != null;
                    return isiPad;
                }
            }

            var dynamicTitles = {

                /**
                * Create title with empty placeholders for variables
                * @param {} text 
                * @returns {} 
                */
                replaceVariablesWithEmpty: function (text) {

                    var variables = text.match(/\{(.*?)\}/g);
                    if (!variables) {
                        return text;
                    }

                    var length = variables.length;
                    for (var i = 0; i < length; i++) {
                        text = text.replace(variables[i], "");
                    }

                    return text;
                },

                /**
                * Create array with variables data
                * @param {} text 
                * @returns {} 
                */
                getVariables: function (text) {
                    var variables = text.match(/\{(.*?)\}/g);
                    if (!variables) {
                        return null;
                    }
                    var length = variables.length;
                    var result = [];
                    for (var i = 0; i < length; i++) {
                        result.push({
                            name: variables[i].replace("{", "").replace("}", ""), //eg Code
                            value: variables[i] //eg {Code}
                        });
                    }
                    return result;
                },

                /**
                * Renamed _has method definition from underscore.js
                * @param {} object 
                * @param {} key 
                * @returns {} 
                */
                hasProperty: function (object, key) {
                    return object ? hasOwnProperty.call(object, key) : false;
                },

                /**
                 * Show on console debug info wtf
                 * @param {} portlet 
                 * @param {} propertyName 
                 * @returns {} 
                 */
                showWrongVariableInfo: function (portlet, propertyName) {
                    console.warn("Title variable '{" +
                        propertyName +
                        "}' does not exists in master portlet or has null or empty value. Portlet details:");
                    console.table({
                        Id: $scope.portlet.Id,
                        InternalName: $scope.portlet.InternalName,
                        Title: $scope.portlet.Title,
                        PortletType: $scope.portlet.PortletType
                    });
                }
            };

            var variables = null;
            if ($scope.portlet) {
                $scope.portlet.ParsedTitle = dynamicTitles.replaceVariablesWithEmpty($scope.portlet.ParsedTitle = translationsService.get($scope.portlet.Code + '-Title', $scope.portlet.Title));
                variables = dynamicTitles.getVariables(translationsService.get($scope.portlet.Code + '-Title', $scope.portlet.Title));
            }

            $scope.$on('onRowSelected',
                function (event, data) {

                    if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {

                        if (variables) {
                            //fill variables with values from event
                            variables.forEach(function (variable) {
                                variable.bindValue = data.Data[variable.name];
                            });

                            //Parse title with variables
                            var tmpTitle = translationsService.get($scope.portlet.Code + '-Title', $scope.portlet.Title);

                            variables.forEach(function (variable) {
                                tmpTitle = tmpTitle.replace(variable.value,
                                    variable.bindValue ? variable.bindValue : "");
                                $scope.portlet.ParsedTitle = tmpTitle;
                                if (!variable.bindValue) {
                                    dynamicTitles.showWrongVariableInfo($scope.portlet, variable.name);
                                }
                            });
                            titleService.setTitle($scope.portlet.Id, $scope.portlet.ParsedTitle);
                        }
                    }
                });

            $scope.$on('onFilter',
                function (event, data) {

                    if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                        if (variables) {
                            //fill variables with values from event
                            variables.forEach(function (variable) {
                                var key;
                                try {
                                    key = _.find(data.Data, function (x) { return x.Key === variable.name });

                                    variable.bindValue = key.Value;
                                } catch (e) {
                                    variable.bindValue = null;
                                    dynamicTitles.showWrongVariableInfo($scope.portlet, variable.name);
                                }
                            });

                            //Parse title with variables
                            var tmpTitle = $scope.portlet.Title;
                            variables.forEach(function (variable) {
                                tmpTitle = tmpTitle.replace(variable.value,
                                    variable.bindValue ? variable.bindValue : "");
                                $scope.portlet.ParsedTitle = tmpTitle;
                            });
                            titleService.setTitle($scope.portlet.Id, $scope.portlet.ParsedTitle);
                        }
                    }
                });

            $scope.$on('onGenericTreeNodeSelected',
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                        if (variables) {

                            //data.Data can contains only one element, therefore even if more elements exists, they are ignored.
                            var firstVar = variables[0];
                            dynamicTitles.hasProperty(data.Data, firstVar.name)
                                ? firstVar.bindValue = data.Data.OBJKEY
                                : dynamicTitles.showWrongVariableInfo($scope.portlet, firstVar.name);


                            //Parse title with variables
                            var tmpTitle = $scope.portlet.Title;
                            tmpTitle = tmpTitle.replace(firstVar.value, firstVar.bindValue ? firstVar.bindValue : "");
                            $scope.portlet.ParsedTitle = tmpTitle;

                            titleService.setTitle($scope.portlet.Id, $scope.portlet.ParsedTitle);
                        }
                    }
                });

            $scope.$on("onRefreshPush",
                function (event, data) {
                    if ($scope.portlet.Id == data.PortletId) {
                        switch (data.PortletType) {
                            case "Table":
                            case "Chart2":
                            case "FlowApplicationTasks":
                                $scope.refreshSilently();
                                break;
                            case "GenericTree":
                                $scope.refreshTree();
                                break;
                            case "DataVisualizerCard":
                                $scope.getData();
                                break;
                            case "DocManList":
                                $scope.docListOptions.dataSource.read();
                                break;
                            case "ProcessOverview":
                                angular.forEach($scope.configurationModel.LaneModel.lanes,
                                    function (lane) {
                                        lane.gridOptions.dataSource.read();
                                    });
                                break;
                            case "ResourceBuckets":
                                $scope.DataModel.RefreshWorkflowCache = true;
                                $scope.getData();
                                break;
                            case "DataVisualizerCardNavigator":
                            case "DocManTree":
                            case "Filter":
                            case "Inbox":
                            case "NewsViewer":
                            case "Text":
                                angular.noop();
                                break;
                            default:
                                $scope.refresh();
                                break;
                        }
                    }
                });

            $scope.BelongsToActiveTab = function () { 
                var urlTabId = urlQueryService.getTabId();
                var portletContainerTabId = $scope.portlet.TabId;
                return urlTabId == portletContainerTabId;
            };
        }
    ]);