app.service('SchedulerService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getTasks = function (model, portletId) {
			return basePluginService.backend.post("schedulerTasksAndAssignments", model, portletId, true);
        };
		
        service.getResources = function (model, portletId) {
			return basePluginService.backend.post("schedulerResources", model, portletId, true);
		};

	    service.createFields = function () {
		    var toReturn = {
			    id: { from: "Id", type: "string" },
			    start: { from: "Start", type: "date" },
			    end: { from: "End", type: "date" },
			    title: { from: "Title", defaultValue: "", type: "string" },
			    color: { from: "Color", defaultValue: "", type: "string" },
			    resourceIds: { from: "ResourceIds", nullable: true }
		    };

		    return toReturn;
	    };

	    service.buildGroups = function (resources,groupColumnNamne) {

			if (!groupColumnNamne)
			    return [];

		    var uniqGroups = _.groupBy(resources, "Group");
		    var uniqTitles = Object.keys(uniqGroups);

		    return uniqTitles.map(function (group) {
			    return {
				    Checked: true,
				    Title: group
			    };
		    });
	    };
		

        return service;

    }]);