app.controller('ResourceBuckets_CreateOrEditPortletController', [
	'$scope',
	'$uibModalInstance',
	'createOrEditModalService',
	'resourceBucketsConfigurationService',
	'configurationService',
	'propertyService',
	'editorService',
	'data',
	'connectorService',
	'kendoGridUtilService',
	'dataEvalService', 'pluginConfigurationService', 'Guid',
	function (
		$scope,
		$uibModalInstance,
		createOrEditModalService,
		resourceBucketsConfigurationService,
		configurationService,
		propertyService,
		editorService,
		data,
		connectorService,
		kendoGridUtilService,
		dataEvalService,
		pluginConfigurationService,
		Guid) {

		$scope.model = createOrEditModalService.getModel("ResourceBuckets", data);
		$scope.configurationModel =
			pluginConfigurationService.getConfigurationModel($scope.model.Configuration, "ResourceBuckets");

		if (!$scope.configurationModel.ColorRulesConfiguration.DefaultColor) {
			$scope.configurationModel.ColorRulesConfiguration.DefaultColor = 'rgb(92, 186, 230)';
		}
		pluginConfigurationService.assignPositions($scope.configurationModel.ColorRulesConfiguration.ColorRules);
		pluginConfigurationService.assignPositions($scope.configurationModel.IconsConfiguration.Icons);
		pluginConfigurationService.assignPositions($scope.configurationModel.FlowConfiguration.CustomFlows);
		pluginConfigurationService.assignPositions($scope.configurationModel.TooltipConfiguration.Fields);

		if ($scope.configurationModel.TooltipConfiguration != null) {
			if (!$scope.configurationModel.TooltipConfiguration.Code) {
				$scope.configurationModel.TooltipConfiguration.Code = Guid.newGuid();
			}
			if (Guid.isEmpty($scope.configurationModel.TooltipConfiguration.Code)) {
				$scope.configurationModel.TooltipConfiguration.Code = Guid.newGuid();
			}
		}

		$scope.connectorService = connectorService;
		$scope.editorService = editorService;

		$scope.panelBarOptions = {
			expandMode: "single",
			select: $scope.expandCollapse
		};

		$scope.createWorkflowTypes = [{
			"type": "dsWithPagingAndFiltering",
			"displayName": "Data Source with paging and filtering"
		}, {
			"type": "datasource",
			"displayName": "Data Source"
		}
		];

		$scope.iconGrid = {
			editable: true,
			scrollable: false,
			toolbar: [{ name: "create", text: "Add" }],
			columns: [
				{ field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="iconId_#: Position #" kendo-droptarget k-drop="onIconDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
				{ field: "Icon", title: "Icon", width: "50px" },
				{ field: "FirstValue", title: "First Value", width: "150px" },
				{ field: "Func", title: "Func", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'), template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func') },
				{ field: "SecondValue", title: "Second Value", width: "150px" },
				{ command: [{ name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 24 }
			],
			dataSource: new kendo.data.DataSource({
				data: $scope.configurationModel.IconsConfiguration.Icons,
				autoSync: true,
				transport: {
					read: function (o) {
						o.success($scope.configurationModel.IconsConfiguration.Icons);
					},
					create: function (o) {
						var item = o.data;
						item.Position = $scope.configurationModel.IconsConfiguration.Icons.length + 1;
						$scope.configurationModel.IconsConfiguration.Icons.unshift(item);
						o.success(item);
					},
					update: function (o) {
						o.success();
					},
					destroy: function (o) {
						for (var index = 0; index < $scope.configurationModel.IconsConfiguration.Icons.length; index++) {
							if ($scope.configurationModel.IconsConfiguration.Icons[index].Position === o.data.Position) {
								$scope.configurationModel.IconsConfiguration.Icons.splice(index, 1);
								break;
							}
						}
						o.success();
					}
				},
				schema: {
					model: {
						id: "Position",
						fields: {
							Position: { type: "int", defaultValue: 0 },
							FirstValue: { type: "string" },
							Func: { type: "string", defaultValue: 1 },
							SecondValue: { type: "string" },
							Icon: { type: "string" }
						}
					}
				}
			})
		};
		$scope.cardOptions = {
			editable: true,
			scrollable: false,
			toolbar: [
				{
					name: "create", text: "Add"
				}
			],
			columns: [
				{ field: "DatabaseColumn", title: "Datasource column", width: "400px" },
				{ field: "Editor", title: "Editor", width: "400px", editor: editorService.createBasicEditorDropDownList },
				{
					command: [
						{
							name: "destroy",
							template:
								"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
						}
					],
					width: 6
				}
			],
			dataSource: new kendo.data.DataSource({
				data: $scope.configurationModel.Mappers,
				autoSync: true,
				transport: {
					read: function (o) {
						if (!$scope.configurationModel.CardConfiguration.Fields) {
							$scope.configurationModel.CardConfiguration.Fields = [];
						}
						o.success($scope.configurationModel.CardConfiguration.Fields);
					},
					create: function (o) {
						var item = o.data;
						item.Position = $scope.configurationModel.CardConfiguration.Fields.length + 1;
						$scope.configurationModel.CardConfiguration.Fields.unshift(item);
						o.success(item);
					},
					update: function (o) {
						o.success();
					},
					destroy: function (o) {

						for (var index = 0; index < $scope.configurationModel.CardConfiguration.Fields.length; index++) {
							if ($scope.configurationModel.CardConfiguration.Fields[index].Position == o.data.Position) {
								$scope.configurationModel.CardConfiguration.Fields.splice(index, 1);
								break;
							}
						}
						o.success();
					}
				},
				schema: {
					model: {
						id: "Position",
						fields: {
							DatabaseColumn: { type: "string" },
							Editor: { defaultValue: "Text" }
						}
					}
				}
			})
		};
		$scope.tooltipOptions = {
			editable: true,
			scrollable: false,
			toolbar: [
				{
					name: "create", text: "Add"
				}
			],
			columns: [
				{ field: "DatabaseColumn", title: "Datasource column", width: "400px" },
				{ field: "Editor", title: "Editor", width: "400px", editor: editorService.createBasicEditorDropDownList },
				{
					command: [
						{
							name: "destroy",
							template:
								"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
						}
					],
					width: 6
				}
			],

			dataSource: new kendo.data.DataSource({
				data: $scope.configurationModel.Mappers,
				autoSync: true,
				transport: {
					read: function (o) {
						if (!$scope.configurationModel.TooltipConfiguration.Fields) {
							$scope.configurationModel.TooltipConfiguration.Fields = [];
						}
						o.success($scope.configurationModel.TooltipConfiguration.Fields);
					},
					create: function (o) {
						var item = o.data;
						item.Position = $scope.configurationModel.TooltipConfiguration.Fields.length + 1;
						$scope.configurationModel.TooltipConfiguration.Fields.unshift(item);
						o.success(item);
					},
					update: function (o) {
						o.success();
					},
					destroy: function (o) {

						for (var index = 0; index < $scope.configurationModel.TooltipConfiguration.Fields.length; index++) {
							if ($scope.configurationModel.TooltipConfiguration.Fields[index].Position == o.data.Position) {
								$scope.configurationModel.TooltipConfiguration.Fields.splice(index, 1);
								break;
							}
						}

						o.success();
					}
				},
				schema: {
					model: {
						id: "Position",
						fields: {
							DatabaseColumn: { type: "string" },
							Editor: { defaultValue: "Text" }
						}
					}
				}
			})
		};
		editorService.createColumnNameComboBox($scope.iconGrid.columns[2], $scope.model);
		editorService.createColumnNameComboBox($scope.tooltipOptions.columns[0], $scope.model);
		editorService.createColumnNameComboBox($scope.cardOptions.columns[0], $scope.model);

		$scope.iconGrid.dataSource.fetch(function () {
			$scope.iconGrid.dataSource.sort({ field: "Position", dir: "asc" });
		});

		$scope.colorPickerOptions = function () {
			return {
				columns: 12,
				tileSize: {
					width: 34,
					height: 34
				},
				palette: $.VisualPlanningColorList
			};
		}

		$scope.colorGrid = {
			editable: true,
			scrollable: false,
			toolbar: [{ name: "create", text: "Add" }],
			columns: [
				{
					field: "Position",
					title: "Pos",
					width: 24,
					readonly: true,
					editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'),
					template:
						'<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>'
				},
				{
					field: "RuleName",
					title: "Legend name",
					width: "100px"
				},
				{
					field: "Colour",
					title: "Colour",
					width: "100px",
					editor: kendoGridUtilService.getColorPickerFromPalette(),
					template: kendoGridUtilService.getColorTemplate()
				},
				{ field: "FirstValue", title: "First Value", width: "100px" },
				{
					field: "Func",
					title: "Func",
					width: "100px",
					editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
					template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
				},
				{ field: "SecondValue", title: "Second Value", width: "100px" },
				{
					command: [
						{
							name: "destroy",
							template:
								"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
						}
					],
					width: 24
				}
			],
			dataSource: new kendo.data.DataSource({
				data: $scope.configurationModel.ColorRulesConfiguration.ColorRules,
				autoSync: true,
				transport: {
					read: function (o) {
						o.success($scope.configurationModel.ColorRulesConfiguration.ColorRules);
					},
					create: function (o) {
						var item = o.data;
						item.Position = $scope.configurationModel.ColorRulesConfiguration.ColorRules.length + 1;
						$scope.configurationModel.ColorRulesConfiguration.ColorRules.unshift(item);
						o.success(item);
					},
					update: function (o) {
						o.success();
					},
					destroy: function (o) {
						for (var index = 0; index < $scope.configurationModel.ColorRulesConfiguration.ColorRules.length; index++) {
							if ($scope.configurationModel.ColorRulesConfiguration.ColorRules[index].Position === o.data.Position) {
								$scope.configurationModel.ColorRulesConfiguration.ColorRules.splice(index, 1);
								break;
							}
						}
						o.success();
					}
				},
				schema: {
					model: {
						id: "Position",
						fields: {
							RuleName: { type: "string" },
							Position: { type: "int", defaultValue: 0 },
							FirstValue: { type: "string" },
							Func: { type: "string", defaultValue: 1 },
							SecondValue: { type: "string" },
							Colour: {
								type: "string",
								defaultValue: "#5cbae6",
                                validation: {
                                    required: { message: "Field is required"}  }
							}

						}
					}
				}
			})
		};
		editorService.createColumnNameComboBox($scope.colorGrid.columns[3], $scope.model);
		$scope.colorGrid.dataSource.fetch(function () {
			$scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
		});

		$scope.flowOptions = {
			editable: "inline",
			scrollable: false,
			edit: function (e) {
				var commandCell = e.container.find("td:last");
				commandCell.html(
					"<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
					"<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
				);
			},
			toolbar: [{ name: "create", text: "Add" }],
			columns: [
				{ field: "DisplayName", title: "Display Name", width: "150px" },
				{
					field: "CustomFlow",
					title: "Custom Flow",
					width: "200px",
					editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
					template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
				},
				{
					field: "AlwaysEnabled",
					title: "Always Enabled",
					width: "70px",
					template: "<input type='checkbox' #= AlwaysEnabled ? checked='checked' : '' # ></input>",
					editor: kendoGridUtilService.makeKendoCheckboxFromValue("AlwaysEnabled")
				},
				{ field: "LeftValue", title: "Left Value", width: "150px" },
				{
					field: "Func",
					title: "Func",
					width: "100px",
					editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
					template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
				},
				{ field: "RightValue", title: "Right Value", width: "150px" },
				{
					command: [
						{
							name: "edit",
							template: "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
						},
						{
						    name: "up",
						    template: "<a class='k-button k-button-icontext gridButton' ng-click='groupUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
						},
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
						{
							name: "destroy",
							template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
						}
					],
					width: 24
				}],

			dataSource: new kendo.data.DataSource({
				data: $scope.configurationModel.FlowConfiguration.CustomFlows,
				autoSync: true,
				transport: {
					read: function (o) {
						o.success($scope.configurationModel.FlowConfiguration.CustomFlows);
					},
					create: function (o) {
						var item = o.data;
						item.Position = $scope.configurationModel.FlowConfiguration.CustomFlows + 1;
						item.Code = Guid.newGuid();
						$scope.configurationModel.FlowConfiguration.CustomFlows.unshift(item);
						o.success(item);
					},
					update: function (o) {
						o.success();
					},
					destroy: function (o) {
						for (var index = 0;
							index < $scope.configurationModel.FlowConfiguration.CustomFlows.length;
							index++) {
							if ($scope.configurationModel.FlowConfiguration.CustomFlows[index].Position == o.data.Position) {
								$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index, 1);
								break;
							}
						}
						o.success();
					}
				},
				schema: {
					model: {
						id: "Position",
						fields: {
							DisplayName: {
								type: "string",
                                validation: {
                                    required: { message: "Field is required"}  }
							},
							CustomFlow: {
								validation: {
									required: true
								}
							},
							AlwaysEnabled: { type: "boolean" },
							LeftValue: { type: "string" },
							Func: { type: "string", defaultValue: "1" },
							RightValue: { type: "string" }
						}
					}
				}
			})
		};
		editorService.createNotMandatoryColumnNameComboBox($scope.flowOptions.columns[3], $scope.model);

		$scope.Workflows = [];


		$scope.validateModel = function () {

			if ($scope.model.DataSourceType === 'Database' && $scope.model.Queries.length !== 3) {
				return false;
			}
			if ($scope.model.DataSourceType === 'Workflow' && $scope.model.Workflows.length !== 3) {
				return false;
			}

			if ($scope.model.DataSourceType === 'Workflow') {
				for (var i = 0; i < $scope.model.Workflows.length; i++) {
					if (!$scope.model.Workflows[i].Name || !$scope.model.Workflows[i].OutputVariable) {
						return false;
					}
				}
			}

			if (!$scope.configurationModel.TasksDatasource.TaskIdColumnName)
				return false;
			if (!$scope.configurationModel.TasksDatasource.TaskStartColumnName)
				return false;
			if (!$scope.configurationModel.TasksDatasource.TaskEndColumnName)
				return false;
			if (!$scope.configurationModel.ResourcesDatasource.ResourceIdColumnName)
				return false;
			if (!$scope.configurationModel.RelationDatasource.UnitsColumnName)
				return false;
			if (!$scope.configurationModel.RelationDatasource.ResourceIdColumnName)
				return false;
			if (!$scope.configurationModel.RelationDatasource.TaskIdColumnName)
				return false;

			return true;
		}


		$scope.validateDataSource = function () {

			if (!$scope.model.DataSourceType) {
				return false;
			}
			if ($scope.model.DataSourceType === 'Workflow') {
				if (!$scope.portletConfigurationForm.outputVariable) {
					return false;
				}
				return $scope.model.Workflows.length > 2;
			}
			if ($scope.model.DataSourceType === 'Database') {
				if (!$scope.model.Queries[0].ConnectorName || !$scope.model.Queries[1].ConnectorName || !$scope.model.Queries[2].ConnectorName) {
					return false;
				}
			}
			return true;
		};

		function assignTranslationCodes() {

			if ($scope.configurationModel.CardConfiguration.DataRowHeader && !$scope.configurationModel.CardConfiguration.DataRowHeader.Code) {
				$scope.configurationModel.CardConfiguration.DataRowHeader.Code = Guid.newGuid();
			}

			if ($scope.configurationModel.CardConfiguration.DataRowOne && !$scope.configurationModel.CardConfiguration.DataRowOne.Code) {
				$scope.configurationModel.CardConfiguration.DataRowOne.Code = Guid.newGuid();
			}

			if ($scope.configurationModel.CardConfiguration.DataRowTwo != null) {
				if (!$scope.configurationModel.CardConfiguration.DataRowTwo.Code) {
					$scope.configurationModel.CardConfiguration.DataRowTwo.Code = Guid.newGuid();
				}
			}

			if ($scope.configurationModel.CardConfiguration.DataRowThree != null) {
				if (!$scope.configurationModel.CardConfiguration.DataRowThree.Code) {
					$scope.configurationModel.CardConfiguration.DataRowThree.Code = Guid.newGuid();
				}
			}

			if ($scope.configurationModel.CardConfiguration.DataRowFour != null) {
				if (!$scope.configurationModel.CardConfiguration.DataRowFour.Code) {
					$scope.configurationModel.CardConfiguration.DataRowFour.Code = Guid.newGuid();
				}
			}
		};

		$scope.ok = function () {
			//need stay here, as portletContainerLayouts using this.
			assignTranslationCodes();
			configurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
			$scope.model.Configuration = JSON.stringify($scope.configurationModel);
			$uibModalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
	}])