app.service('FlowApplicationOverviewService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};


        service.getApplicationHeader = function (paginationModel,portletId) {
            return basePluginService.backend.post("applicationHeader", paginationModel,portletId);
        };

        service.getApplications = function (portletId) {
            return basePluginService.backend.get("applications", portletId);
        };

        service.getApplicationInstances = function (paginationModel,portletId) {
            return basePluginService.backend.post("application", paginationModel,portletId);
        };


        service.getApplicationColumns= function(config) {

            var paginationModel = {
                PageNumber:0,     
                PageSize:100
            };

            var columnsDataSource = {
                transport: {
                    cache: false,
                    serverFiltering: true,
                    read: function (opts) {

                        if (config.Application) {
                            paginationModel.ApplicationIdentifier=config.Application.Id,  
                            service.getApplicationHeader(paginationModel)
                                .then(function (result) {
                                    opts.success(result.data.Headers);
                                }).catch(angular.noop);
                        }
                        else {
                                opts.success([]);
                            }
                    }
                }
            };

            return {
                filter: "contains",
                dataSource: columnsDataSource,
                dataTextField: "ColumnCode",
                dataValueField: "ColumnCode"
            };      


        }


        service.getAppsOptions = function () {
            var appsDataSource = {
                transport: {
                    read: function (opts) {
                        basePluginService.backend.get("applications", true)
                            .then(function (result) {
                                opts.success(result.data);
                            }).catch(angular.noop);
                    }
                }
            };

            return {
                filter: "contains",
                dataSource: appsDataSource,
                dataTextField: "Name",
                dataValueField: "Id"
            };
        }
   
        service.exportTable = function (paginationModel, portletId) {
            return basePluginService.backend.postWithHeaders("action/overviewTableExport", paginationModel, {}, portletId, true);
        };


        return service;

    }]);