app.controller("Kanban_CreateOrEditPortletController",
    ['$scope', '$http', '$uibModalInstance', 'createOrEditModalService', 'configurationService', 'data',
        'pluginConfigurationService', 'connectorService', 'editorService', 'kendoGridUtilService', 'kanbanService', 'dataEvalService', 'Guid', '$timeout', 
        function ($scope, $http, $uibModalInstance, createOrEditModalService, configurationService, data,
            pluginConfigurationService, connectorService, editorService, kendoGridUtilService, kanbanService, dataEvalService, Guid, $timeout) {

            $scope.model = createOrEditModalService.getModel("Kanban", data);
            $scope.configurationModel = pluginConfigurationService.getConfigurationModel($scope.model.Configuration, "Kanban");
            if (!$scope.configurationModel.ColorRulesConfiguration.DefaultColor) {
                $scope.configurationModel.ColorRulesConfiguration.DefaultColor = "rgb(92, 186, 230)";
            }

            $scope.connectorService = connectorService;
            $scope.editorService = editorService;

            $scope.panelBarOptions = {
                expandMode: "single",
                select: $scope.expandCollapse
            };

            pluginConfigurationService.assignPositions($scope.configurationModel.ColorRulesConfiguration.ColorRules);
            pluginConfigurationService.assignPositions($scope.configurationModel.IconsConfiguration.Icons);
            pluginConfigurationService.assignPositions($scope.configurationModel.FlowConfiguration.CustomFlows);
            pluginConfigurationService.assignPositions($scope.configurationModel.ColumnsConfigurations);
            pluginConfigurationService.assignPositions($scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields);

            $scope.defaultColumnColor = "#BBBBBB";
            
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering"
                }, {
                    "type": "datasource",
                    "displayName": "Data Source"
                }
            ];

            $scope.validateDataSource = function () {

                if (!$scope.configurationModel.TasksDatasource.TaskIdColumnName || $scope.configurationModel.TasksDatasource.TaskIdColumnName == '') {
                    return false;
                }
                if (!$scope.configurationModel.TasksDatasource.TaskStatusColumnName || $scope.configurationModel.TasksDatasource.TaskStatusColumnName == '') {
                    return false;
                }
                if (!$scope.configurationModel.TasksDatasource.TaskOrderIdColumnName || $scope.configurationModel.TasksDatasource.TaskOrderIdColumnName == '') {
                    return false;
                }
                if (!$scope.configurationModel.TasksDatasource.TaskOrderSortDirection || $scope.configurationModel.TasksDatasource.TaskOrderSortDirection == '') {
                    return false;
                }
                return true;
            };

            $scope.colorPickerOptions = function () {
                return {
                    columns: 12,
                    tileSize: {
                        width: 34,
                        height: 34
                    },
                    palette: $.VisualPlanningColorList
                };
            }

            $scope.kanbanColumnsOptions = {


                /**
                 * Custom function
                 * @returns {} 
                 */
                removeStatusesMappings: function () {
                    $scope.configurationModel.ColumnsConfigurations =[];
                    var dataSource = $scope.kanbanColumnsConfigurationGrid.options.dataSource;
                    dataSource.data([]);
                },
                /**
                 * Custom function
                 * @returns {} 
                 */
                mapStatusesToColumns: function () {

                    $scope.PaginationModel = {};
                    $scope.PaginationModel.Query = $scope.model.Queries.length ? $scope.model.Queries[0].Value : null;
                    $scope.PaginationModel.ConnectorName = $scope.model.Queries.length ? $scope.model.Queries[0].ConnectorName : null;
                    $scope.PaginationModel.Group = {
                        Code: $scope.configurationModel.TasksDatasource.TaskStatusColumnName,
                        Order: "asc"
                    };
                    $scope.PaginationModel.Search = [];
                    $scope.PaginationModel.PageSize = 100000; //hardcoded
                    $scope.PaginationModel.WorkflowId = $scope.model.Workflows.length ? $scope.model.Workflows[0].WorkflowId : null;
                    $scope.PaginationModel.OutputVariable = $scope.model.Workflows.length ? $scope.model.Workflows[0].OutputVariable : null;
                    $scope.PaginationModel.DataSourceType = $scope.model.DataSourceType;

                    kanbanService.getStatuses($scope.PaginationModel).then(
                        function (response) {

                            $scope.kanbanColumnsOptions.removeStatusesMappings();

                            var dataSource = $scope.kanbanColumnsConfigurationGrid.options.dataSource;
                            response.data.Groups.forEach(function (g, idx) {
                                var tmpStatus = {
                                    Position: idx,
                                    OrderId: idx + 1,
                                    StatusValue: g.value,
                                    Text: g.value,
                                    Color: $scope.defaultColumnColor,
                                    MaxCount: 10,
                                    NoMaxCountLimit: true,
                                    Visible: true
                                };
                                dataSource.add(tmpStatus);
                                $scope.configurationModel.ColumnsConfigurations.push(tmpStatus);
                            });
                            dataSource.sync();
                        },
                        function (err) {
                            console.log(err);
                        }).catch(angular.noop);
                },

                editable: "inline",
                scrollable: false,
                edit: function (e) {
                    var commandCell = e.container.find("td:last");
                    commandCell.html(
                        "<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
                        "<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
                    );
                },
                toolbar: [
                    { name: "create", text: "Add" },
                    {
                        name: 'map',
                        template: '<a class="k-button" ng-click="kanbanColumnsOptions.mapStatusesToColumns()">Map statuses to columns</a>'
                    },
                    {
                        name: 'removeAll',
                        template: '<a class="k-button" ng-click="kanbanColumnsOptions.removeStatusesMappings()">Remove all</a>'
                    }],
                columns: [
                    { field: "StatusValue", title: "Status Value", width: "150px" },
                    { field: "OrderId", title: "Order Id", width: "100px" },
                    { field: "Text", title: "Display Text (Title)", width: "150px" },
                    {
                        field: "Color",
                        title: "Color",
                        width: "100px",
                        editor: kendoGridUtilService.getColorPickerFromPalette(),
                        template: kendoGridUtilService.getColorTemplate("Color")
                    },
                    { field: "MaxCount", title: "Max tasks count", width: "150px" },
                    {
                        field: "NoMaxCountLimit",
                        title: "Unlimited count",
                        width: "50px",
                        template: "<input type='checkbox' #= NoMaxCountLimit ? checked='checked' : '' # ></input>",
                        editor: kendoGridUtilService.makeKendoCheckboxFromValue("NoMaxCountLimit")
                    },
                    {
                        field: "Visible",
                        title: "Visible",
                        width: "50px",
                        template: "<input type='checkbox' #= Visible ? checked='checked' : '' # ></input>",
                        editor: kendoGridUtilService.makeKendoCheckboxFromValue("Visible")
                    },
                    {
                        command: [
                            {
                                name: "edit",
                                template:
                                    "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
                            },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 24
                    }
                ],

                dataSource: new kendo.data.DataSource({

                    data: $scope.configurationModel.ColumnsConfigurations,
                    autoSync: false,
                    transport: {
                        read: function (o) {
                            o.success($scope.configurationModel.ColumnsConfigurations);
                        },
                        create: function (o) {
                            var item = o.data;

                            var maxOrderId = 0;
                            if ($scope.configurationModel.ColumnsConfigurations.length) {
                                var orderIds = $scope.configurationModel.ColumnsConfigurations.map(function(c) {
                                    return c.OrderId;
                                });
                                maxOrderId = Math.max.apply(Math, orderIds);
                            }

                            item.Position = $scope.configurationModel.ColumnsConfigurations.length + 1;
                            item.OrderId = maxOrderId + 1;

                            $scope.configurationModel.ColumnsConfigurations.unshift(item);
                            o.success(item);

                        },
                        update: function (o) {
                            $scope.configurationModel.ColumnsConfigurations.map(function (cc) {
                                if (cc.Position == o.data.Position) {
                                    Object.assign(cc, o.data);
                                }
                            });
                            o.success();
                        },
                        destroy: function (o) {

                            //find items with bigger ids than removed one
                            var itemsWithGreaterId = $scope.configurationModel.ColumnsConfigurations.filter(function(c) {
                                return c.OrderId > o.data.OrderId;
                            });

                            $scope.configurationModel.ColumnsConfigurations = $scope.configurationModel.ColumnsConfigurations
                                //remove item requested to remove from configurationModel
                                .filter(function (c) {
                                    return c.Position !== o.data.Position;
                                })
                                //find bigger and decrease Position and orderId
                                .filter(function (c) {
                                    if (itemsWithGreaterId.indexOf(c) > -1) {
                                        c.Position = c.Position - 1;
                                        c.OrderId = c.OrderId - 1;
                                    }
                                    return c;
                                });

                            o.success();

                            var dataSource = $scope.kanbanColumnsConfigurationGrid.options.dataSource;
                            dataSource.read();
                            //datasurce.sync()?

                        }
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                OrderId: {
                                    type: "number",
                                    defaultValue: 1,
                                    editable: true, 
                                    validation: { required: false, min: 0}
                                },
                                StatusValue: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"} }
                                },
                                Text: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"} }
                                },
                                Color: {
                                    type: "string",
                                    defaultValue: $scope.defaultColumnColor, //"#337ab7" (bootstrap blue),
                                    validation: {
                                        required: { message: "Field is required"}  }
                                },
                                MaxCount: {
                                    type: "number",
                                    defaultValue: 10,
                                    validation: { required: true, min: 1 }
                                },
                                NoMaxCountLimit: {
                                    type: "boolean",
                                    defaultValue: true,
                                    validation: { required: false}
                                },
                                Visible: {
                                    type: "boolean",
                                    defaultValue: true,
                                    validation: { required: false}
                                }
                            }
                        }
                    }
                }),
            };

            $scope.colorRulesGridOptions = {
                editable: true,
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    {
                        field: "Position",
                        title: "Pos",
                        width: 24,
                        readonly: true,
                        editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'),
                        template:
                            '<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>'
                    },
                    {
						field: "Colour",
						title: "Colour",
                        width: "100px",
                        editor: kendoGridUtilService.getColorPickerFromPalette(),
						template: kendoGridUtilService.getColorTemplate("Colour")
                    },
                    {
                        field: "FirstValue",
                        title: "First Value",
                        width: "100px"
                    },
                    {
                        field: "Func",
                        title: "Func",
                        width: "100px",
                        editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                        template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                    },
                    { field: "SecondValue", title: "Second Value", width: "100px" },
                    {
                        command: [
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 24
                    }
                ],
                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.ColorRulesConfiguration.ColorRules,
                    autoSync: true,
                    transport: {
                        read: function (o) {
                            o.success($scope.configurationModel.ColorRulesConfiguration.ColorRules);
                        },
                        create: function (o) {
                            var item = o.data;
                            item.Position = $scope.configurationModel.ColorRulesConfiguration.ColorRules.length + 1;
                            $scope.configurationModel.ColorRulesConfiguration.ColorRules.unshift(item);
                            o.success(item);
                        },
                        update: function (o) {
                            o.success();
                        },
                        destroy: function (o) {
                            for (var index = 0; index < $scope.configurationModel.ColorRulesConfiguration.ColorRules.length; index++) {
                                if ($scope.configurationModel.ColorRulesConfiguration.ColorRules[index].Position === o.data.Position) {
                                    $scope.configurationModel.ColorRulesConfiguration.ColorRules.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        },
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                Position: { type: "int", defaultValue: 0 },
                                FirstValue: { type: "string" },
                                Func: { type: "string", defaultValue: 1 },
                                SecondValue: { type: "string" },
                                Colour: {
                                    type: "string",
                                    defaultValue: "#5cbae6", //#CBC5A7
                                    validation: {
                                        required: { message: "Field is required"} }
                                }

                            }
                        }
                    }
                })
            };
            
            //this is requied if we need add to the end of the list - can't be moved easy inside datasource
            $scope.colorRulesGridOptions.dataSource.fetch(function () {
                $scope.colorRulesGridOptions.dataSource.sort({ field: "Position", dir: "asc" });
            });

            //Make combobox for second column. It's a hack bt, copied.
            editorService.createColumnNameComboBox($scope.colorRulesGridOptions.columns[2], $scope.model);

            $scope.flowOptions = {
                editable: "inline",
                scrollable: false,
                edit: function (e) {
                    var commandCell = e.container.find("td:last");
                    commandCell.html(
                        "<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
                        "<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
                        );
                },
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    { field: "DisplayName", title: "Display Name", width: "150px" },
                    {
                        field: "CustomFlow",
                        title: "Custom Flow",
                        width: "200px",
                        editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
                        template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
                    },
                    {
                        field: "AlwaysEnabled",
                        title: "Always Enabled",
                        width: "70px",
                        template: "<input type='checkbox' #= AlwaysEnabled ? checked='checked' : '' # ></input>",
                        editor: kendoGridUtilService.makeKendoCheckboxFromValue("AlwaysEnabled"),

                    },
                    { field: "LeftValue", title: "Left Value", width: "150px" },
                    {
                        field: "Func",
                        title: "Func",
                        width: "100px",
                        editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                        template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                    },
                    { field: "RightValue", title: "Right Value", width: "150px" },
                    {
                        command: [
                            {
                                name: "edit",
                                template: "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
                            },
                            {
                                name: "destroy",
                                template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 24
                    }],

                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.FlowConfiguration.CustomFlows,
                    autoSync: false,
                    transport: {
                        read: function (o) {
                            if ($scope.configurationModel.FlowConfiguration.CustomFlows) {
                                o.success($scope.configurationModel.FlowConfiguration.CustomFlows);
                            } else {
                                o.success([]);
                                $scope.configurationModel.FlowConfiguration.CustomFlows = [];
                            }
                        },
                        create: function (o) {
                            var item = o.data;
                            item.Position = $scope.configurationModel.FlowConfiguration.CustomFlows + 1;
                            item.Code = Guid.newGuid();
                            $scope.configurationModel.FlowConfiguration.CustomFlows.unshift(item);
                            o.success(item);
                        },
                        update: function (o) {
                            o.success();
                        },
                        destroy: function (o) {
                            for (var index = 0;
                                index < $scope.configurationModel.FlowConfiguration.CustomFlows.length;
                                index++) {
                                if ($scope.configurationModel.FlowConfiguration.CustomFlows[index].Position == o.data.Position) {
                                    $scope.configurationModel.FlowConfiguration.CustomFlows.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        },
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                DisplayName: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"} }
                                },
                                CustomFlow: {
                                    validation: {
                                        required: true
                                    }
                                },
                                AlwaysEnabled: { type: "boolean" },
                                LeftValue: { type: "string" },
                                Func: { type: "string", defaultValue: "1" },
                                RightValue: { type: "string" }
                            }
                        }
                    }
                })
            };

            editorService.createNotMandatoryColumnNameComboBox($scope.flowOptions.columns[3], $scope.model);


            $scope.iconGrid = {
                editable: true,
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    { field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="iconId_#: Position #" kendo-droptarget k-drop="onIconDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
                    { field: "Icon", title: "Icon", width: "50px" },
                    { field: "FirstValue", title: "First Value", width: "150px" },
                    { field: "Func", title: "Func", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'), template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func') },
                    { field: "SecondValue", title: "Second Value", width: "150px" },
                    { command: [{ name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 24 }
                ],
                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.IconsConfiguration.Icons,
                    autoSync: true,
                    transport: {
                        read: function (o) {
                            o.success($scope.configurationModel.IconsConfiguration.Icons);
                        },
                        create: function (o) {
                            var item = o.data;
                            item.Position = $scope.configurationModel.IconsConfiguration.Icons.length + 1;
                            $scope.configurationModel.IconsConfiguration.Icons.unshift(item);
                            o.success(item);
                        },
                        update: function (o) {
                            o.success();
                        },
                        destroy: function (o) {
                            for (var index = 0; index < $scope.configurationModel.IconsConfiguration.Icons.length; index++) {
                                if ($scope.configurationModel.IconsConfiguration.Icons[index].Position === o.data.Position) {
                                    $scope.configurationModel.IconsConfiguration.Icons.splice(index, 1);
                                    break;
                                }
                            }
                            o.success();
                        }
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                Position: { type: "int", defaultValue: 0 },
                                FirstValue: { type: "string" },
                                Func: { type: "string", defaultValue: 1 },
                                SecondValue: { type: "string" },
                                Icon: { type: "string" }
                            }
                        }
                    }
                })
            };

            $scope.tooltipGridOptions = {
                editable: true,
                scrollable: false,
                toolbar: [
                    {
                        name: "create", text: "Add"
                    }
                ],
                columns: [
                    { field: "DatabaseColumn", title: "Datasource column", width: "400px" },
                    { field: "Editor", title: "Editor", width: "400px", editor: editorService.createBasicEditorDropDownList },
                    {
                        command: [
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 6
                    }
                ],

                dataSource: new kendo.data.DataSource({
                    //data: $scope.configurationModel.Mappers,
                    autoSync: true,
                    transport: {
                        read: function (o) {
                            if (!$scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields) {
                                $scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields = [];
                            }
                            o.success($scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields);
                        },
                        create: function (o) {
                            var item = o.data;
                            item.Position = $scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields.length + 1;
                            $scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields.unshift(item);
                            o.success(item);
                        },
                        update: function (o) {
                            o.success();
                        },
                        destroy: function (o) {

                            for (var index = 0; index < $scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields.length; index++) {
                                if ($scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields[index].Position === o.data.Position) {
                                    $scope.configurationModel.CardConfiguration.TooltipConfiguration.Fields.splice(index, 1);
                                    break;
                                }
                            }

                            o.success();
                        }
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                DatabaseColumn: { type: "string" },
                                Editor: { defaultValue: "Text" }
                            }
                        }
                    }
                })
            };

            editorService.createColumnNameComboBox($scope.iconGrid.columns[2], $scope.model);
            editorService.createColumnNameComboBox($scope.tooltipGridOptions.columns[0], $scope.model);

            $scope.iconGrid.dataSource.fetch(function () {
                $scope.iconGrid.dataSource.sort({ field: "Position", dir: "asc" });
            });

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            /**
             * Assign to transatable objects codes if not assigned yet
             * @returns {} 
             */
            function assignTranslationCodes() {

                /*Card configuration*/

                if (!$scope.configurationModel.CardConfiguration.DataRowHeader.Code) {
                    $scope.configurationModel.CardConfiguration.DataRowHeader.Code = Guid.newGuid();
                }

                if (!$scope.configurationModel.CardConfiguration.DataRowOne.Code) {
                    $scope.configurationModel.CardConfiguration.DataRowOne.Code = Guid.newGuid();
                }

                if ($scope.configurationModel.CardConfiguration.DataRowTwo != null) {
                    if (!$scope.configurationModel.CardConfiguration.DataRowTwo.Code) {
                        $scope.configurationModel.CardConfiguration.DataRowTwo.Code = Guid.newGuid();
                    }
                }

                if ($scope.configurationModel.CardConfiguration.DataRowThree != null) {
                    if (!$scope.configurationModel.CardConfiguration.DataRowThree.Code) {
                        $scope.configurationModel.CardConfiguration.DataRowThree.Code = Guid.newGuid();
                    }
                }

                if ($scope.configurationModel.CardConfiguration.DataRowFour != null) {
                    if (!$scope.configurationModel.CardConfiguration.DataRowFour.Code) {
                        $scope.configurationModel.CardConfiguration.DataRowFour.Code = Guid.newGuid();
                    }
                }

                if ($scope.configurationModel.CardConfiguration.TooltipConfiguration != null) {
                    if (!$scope.configurationModel.CardConfiguration.TooltipConfiguration.Code) {
                        $scope.configurationModel.CardConfiguration.TooltipConfiguration.Code = Guid.newGuid();
                    }
                    if (Guid.isEmpty($scope.configurationModel.CardConfiguration.TooltipConfiguration.Code)) {
                        $scope.configurationModel.CardConfiguration.TooltipConfiguration.Code = Guid.newGuid();
                    }
                }

                /*Columns configurations */
                $scope.configurationModel.ColumnsConfigurations.map(function(elem) {
                    if (!elem.Code) {
                        elem.Code = Guid.newGuid();
                    }
                    return elem;
                });
            }

            $scope.ok = function () {

                
                /* Translation codes */
                assignTranslationCodes();

                //need stay here, as portletContainerLayouts using this.
                configurationService.saveProperties($scope.model.Properties, $scope.configurationModel);

                $scope.model.Configuration = JSON.stringify($scope.configurationModel);

                $uibModalInstance.close($scope.model);
            };

        }
    ]);