app.controller('ResourceBucketsController',
	['$scope', '$rootScope', 'sharedSessionService', 'portletErrorService', 'resourceBucketsConfigurationService',
		'translationsService', 'translationFlowClientService', '$controller', 'resourceBucketsService', 'imageService', 'pluginConfigurationService',
		'dragulaService',
		'dataEvalService', 'editorService', 'receiverService', 'variableService',
		function ($scope,
			$rootScope,
			sharedSessionService,
			portletErrorService,
			resourceBucketsConfigurationService,
			translationsService,
			translationFlowClientService,
			$controller,
			resourceBucketsService,
			imageService,
			pluginConfigurationService,
			dragulaService,
			dataEvalService,
			editorService, receiverService, variableService) {

			$controller('BasePluginController', { $scope: $scope });
			$controller('OperationAndInputExecutionController', { $scope: $scope });

			$scope.$on('bucket.drag', function (e, el, container) {
				$scope.dragDropData.movedElementId = jQuery(el).attr('data-dragula-element-id');
				$scope.dragDropData.movedElementAssignmentId = jQuery(el).attr('data-dragula-assignment-id');
				$scope.dragDropData.movedElementResourceId = container.parent().parent().attr('data-dragula-resource-id');
				$scope.dragDropData.movedElementBucketId = container.attr('data-dragula-bucket-id');
				$scope.dragDropData.legendId = jQuery(el).attr('data-dragula-legend-id');
				$scope.dragDropData.firstValue = jQuery(el).attr('data-dragula-first-value');
				$scope.dragDropData.secondValue = jQuery(el).attr('data-dragula-second-value');
				$scope.dragDropData.legendOrderId = jQuery(el).attr('data-dragula-legend-order-id');
			});

			$scope.$on('bucket.drop', function (e, el, container) {
				var targetResourceId = container.parent().parent().attr('data-dragula-resource-id');
				var targetBucketId = container.attr('data-dragula-bucket-id');

				var operationInputData = {
					OperationType: 'DragAndDrop',
					TaskId: $scope.dragDropData.movedElementId,
					AssignmentId: $scope.dragDropData.movedElementAssignmentId,
					TargetBucketId: targetBucketId,
					TargetResourceId: targetResourceId,
					SourceResourceId: $scope.dragDropData.movedElementResourceId,
					SourceBucketId: $scope.dragDropData.movedElementBucketId
				};
				var data = [{}];

				if ($scope.dragDropData.legendId) {
					operationInputData = {
						OperationType: 'Create',
						TargetBucketId: targetBucketId,
						TargetResourceId: targetResourceId,
						FirstValue: $scope.dragDropData.firstValue,
						SecondValue: $scope.dragDropData.secondValue,
						LegendName: $scope.dragDropData.legendId,
						OrderId: $scope.dragDropData.legendOrderId
					};

					if ($scope.configurationModel.FlowConfiguration.CreateTask &&
						($scope.configurationModel.FlowConfiguration.CreateTask !== -1) &&
						targetBucketId)
						$scope.runFlow($scope.configurationModel.FlowConfiguration.CreateTask.Id,
							!$scope.configurationModel.FlowConfiguration.CreateTask.IsMachineWorkflow,
							data,
							operationInputData);
					else {

						portletErrorService.showError('Please set create workflow!', $scope.portlet.Id);
						$scope.$apply();

					}
				} else if ($scope.configurationModel.FlowConfiguration.DropTask && ($scope.configurationModel.FlowConfiguration.DropTask !== -1) && targetBucketId) {
					$scope.runFlow($scope.configurationModel.FlowConfiguration.DropTask.Id, !$scope.configurationModel.FlowConfiguration.DropTask.IsMachineWorkflow, data, operationInputData);
				} else {
					portletErrorService.showError('Please set Drag and Drop workflow!', $scope.portlet.Id);
					$scope.$apply();
				}
			});

			$scope.$on("resize",
				function (event, data) {
					if (data) {
						for (var i = 0; i < data.PortletIds.length; i++) {
							if (data.PortletIds[i] === $scope.portlet.Id) {
								$scope.recalculateBuckets();
								break;
							}
						}
					}
				});

			$scope.$on("onMaximized",
				function (event, data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].Id === $scope.portlet.Id) {
							$scope.recalculateBuckets();
							break;
						}
					}
				});

			$scope.$on("onMinimized",
				function (event, data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].Id === $scope.portlet.Id) {
							$scope.recalculateBuckets();
							break;
						}
					}
				});

			$scope.$on("onRowSelected",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
						$scope.DataModel.RowFilter = data.Data;
						$scope.refresh();
					}
				});

			$scope.$on("onRefresh",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
						$scope.refresh();
					}
				});

			$scope.$on("onFilter",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
						$scope.DataModel.Search = data.Data;
						$scope.refresh();
					}
				});

			$scope.$on("onGenericTreeNodeSelected",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
						$scope.DataModel.RowFilter = data.Data;
						$scope.refresh();
					}
				});

			$scope.buildFrameBasedOnBusinessObject = function (nodes, width, height) {
				var frame = {
					frame: { x: 0, y: 0, width: width, height: height },
					nodes: [
						{
							nodes: nodes
						}
					]
				};
				return frame;
			};

			$scope.customFlowDisabled = function (flowConfigId) {

				if (flowConfigId) {

					var flowConfig = $scope.configurationModel.FlowConfiguration.CustomFlows.filter(function (singleConfig) {
						return singleConfig.CustomFlow.Id === flowConfigId;
					})[0];

					if (flowConfig && flowConfig.AlwaysEnabled) {
						return false;
					}
					if ($scope.selectedTask) {
						return !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet($scope.selectedTask),
							'{' + flowConfig.LeftValue + '}',
							flowConfig.Func,
							flowConfig.RightValue);
					};
					return true;
				}
				return false;
			};

			$scope.calculateColor = function (dataItem) {
				for (var i = 0; i < $scope.orderedColorRulesBackground.length; i++) {
					var currentRule = $scope.orderedColorRulesBackground[i];
					if (dataItem.Data) {
						if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
							return currentRule.Colour;
						}
					}
				}
				return $scope.defaultTaskColor;
			};
			$scope.calculateIcon = function (dataItem) {
				for (var i = 0; i < $scope.orderedIconRules.length; i++) {
					var currentRule = $scope.orderedIconRules[i];
					if (dataItem.Data) {
						if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
							return currentRule.Icon;
						}
					}
				}
				return $scope.configurationModel.IconsConfiguration.DefaultIcon;
			};

			$scope.parseVariable = function (text, item, fields) {
				if (!text) {
					return "";
				}
				var variables = text.match(/\{(.*?)\}/g);
				if (!variables) {
					return text;
				}
				variables.forEach(function (v) {
					var variableName = v.replace("{", "").replace("}", "");
					var variableValue = item[variableName];

					var editor = null;

					for (var j = 0; j < fields.length; j++) {
						if (fields[j].DatabaseColumn === variableName)
							editor = fields[j].Editor;
					}

					if (editor === "Date") {
						variableValue = kendo.toString(kendo.parseDate(variableValue), kendo.culture().calendar.patterns.d);
					} else if (editor === "Time") {
						variableValue = kendo.toString(kendo.parseDate(variableValue), kendo.culture().calendar.patterns.T);
					} else if (editor === "DateTime") {
						variableValue = kendo.toString(kendo.parseDate(variableValue), kendo.culture().calendar.patterns.G);
					}
					text = text.replace("{" + variableName + "}", variableValue === null ? "" : variableValue);
				});
				return text;
			}

			$scope.calculateTooltip = function (dataItem) {
				var tooltip = null;
				if (dataItem) {
					if ($scope.configurationModel.TooltipConfiguration &&
						$scope.configurationModel.TooltipConfiguration.Tooltip) {
						tooltip = translationsService.get(
							$scope.configurationModel.TooltipConfiguration.Code + "-Tooltip",
							$scope.configurationModel.TooltipConfiguration.Tooltip);

						tooltip = $scope.parseVariable(tooltip, dataItem.Data, $scope.configurationModel.TooltipConfiguration.Fields);
					}
					else {
						var defaultTemplate = "<p>{Title}</p><p>{Description}</p>";
						tooltip = $scope.parseVariable(defaultTemplate, dataItem, $scope.configurationModel.TooltipConfiguration.Fields);
					}
				}
				return tooltip;
			};

			$scope.parseRow = function (rowModel, item) {
				var obj = {};
				obj.Label = $scope.parseVariable(rowModel.Label, item, $scope.configurationModel.CardConfiguration.Fields);
				obj.Text = $scope.parseVariable(rowModel.Text, item, $scope.configurationModel.CardConfiguration.Fields);
				console.log(obj);
				return obj;
			};

			$scope.displayCards = function (elem) {
				return elem.frame.width > 200 && elem.frame.height >= 180;
			}

			$scope.ruleIsMatching = function (dataItem, rule) {
				return dataEvalService.evaluateExpression(
					dataEvalService.createNewDataSet(dataItem),
					'{' + rule.FirstValue + '}',
					rule.Func,
					rule.SecondValue);
			};

			$scope.calculateItemsForContainer = function (items) {
				var result = [];
				for (var j = 0; j < items.nodes[0].nodes.length; j++) {
					var node = items.nodes[0].nodes[j];
					node.color = $scope.calculateColor(node);

					var iconToUse = $scope.calculateIcon(node);
					if (iconToUse) {
						node.icon = iconToUse;
					} else
						node.icon = null;

					node.HasCardConfig = false;

					if (node.IsVisible) {
						if ($scope.configurationModel.CardConfiguration && $scope.configurationModel.CardConfiguration.DataRowHeader) {
							node.header = $scope.parseRow(
								{
									Label: translationsService.get($scope.configurationModel.CardConfiguration.DataRowHeader.Code + '-Label', $scope.configurationModel.CardConfiguration.DataRowHeader.Label),
									Text: $scope.configurationModel.CardConfiguration.DataRowHeader.Text
								},
								node.Data);
							node.HasCardConfig = true;
						}
						if ($scope.configurationModel.CardConfiguration && $scope.configurationModel.CardConfiguration.DataRowOne) {
							node.row1 =
								$scope.parseRow(
									{
										Label: translationsService.get($scope.configurationModel.CardConfiguration.DataRowOne.Code + '-Label', $scope.configurationModel.CardConfiguration.DataRowOne.Label),
										Text: $scope.configurationModel.CardConfiguration.DataRowOne.Text
									},
									node.Data);
							node.HasCardConfig = true;
						}
						if ($scope.configurationModel.CardConfiguration && $scope.configurationModel.CardConfiguration.DataRowTwo) {
							node.row2 =
								$scope.parseRow(
									{
										Label: translationsService.get($scope.configurationModel.CardConfiguration.DataRowTwo.Code + '-Label', $scope.configurationModel.CardConfiguration.DataRowTwo.Label),
										Text: $scope.configurationModel.CardConfiguration.DataRowTwo.Text
									},
									node.Data);
							node.HasCardConfig = true;
						}
						if ($scope.configurationModel.CardConfiguration && $scope.configurationModel.CardConfiguration.DataRowThree) {
							node.row3 =
								$scope.parseRow(
									{
										Label: translationsService.get($scope.configurationModel.CardConfiguration.DataRowThree.Code + '-Label', $scope.configurationModel.CardConfiguration.DataRowThree.Label),
										Text: $scope.configurationModel.CardConfiguration.DataRowThree.Text
									},
									node.Data);
							node.HasCardConfig = true;
						}
						if ($scope.configurationModel.CardConfiguration && $scope.configurationModel.CardConfiguration.DataRowFour) {
							node.row4 =
								$scope.parseRow(
									{
										Label: translationsService.get($scope.configurationModel.CardConfiguration.DataRowFour.Code + '-Label', $scope.configurationModel.CardConfiguration.DataRowFour.Label),
										Text: $scope.configurationModel.CardConfiguration.DataRowFour.Text
									},
									node.Data);
							node.HasCardConfig = true;
						}
					}
					result.push(node);
				}
				return result;
			};
			$scope.prepareDataForUsersAndResources = function (usersOrResources) {

				for (var i = 0; i < usersOrResources.length; i++) {
					var userOrResource = usersOrResources[i];

					for (var j = 0; j < userOrResource.BucketsAndTasks.length; j++) {

						var tasksPerDay = userOrResource.BucketsAndTasks[j];
						var tasksFrame = $scope.buildFrameBasedOnBusinessObject(tasksPerDay.Tasks,
							$scope.bucketWidth,
							$scope.bucketHeight);
						treemap.squarify(tasksFrame, function () { });
						tasksPerDay.Tasks = $scope.calculateItemsForContainer(tasksFrame);
					}
				}
			};

			$scope.runCustomFlow = function (workflowIdentifier, isMachineWorkflow) {
				var data = [];
				if ($scope.selectedTask)
					data.push($scope.selectedTask);
				$scope.runFlow(workflowIdentifier, !isMachineWorkflow, data);
			};

			$scope.selectTask = function (elem) {
				$scope.selectedTask = elem.Data;
				$rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: elem.Data });
			};

			$scope.runDblClickWorkflow = function (elem) {
				var operationInputData = {
					OperationType: 'DoubleClick',
					TaskId: elem.taskId,
					AssignmentId: elem.AssignmentId
				};

				var data = [];
				data.push(elem.Data);

				if ($scope.configurationModel.FlowConfiguration.DoubleClickTask && ($scope.configurationModel.FlowConfiguration.DoubleClickTask !== -1))
					$scope.runFlow($scope.configurationModel.FlowConfiguration.DoubleClickTask.Id,
						!$scope.configurationModel.FlowConfiguration.DoubleClickTask.IsMachineWorkflow,
						data,
						operationInputData);
			};


			$scope.refresh = function () {
				$rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
				$scope.DataModel.RefreshWorkflowCache = true;
				$scope.getData();
			};
			$scope.filterResource = function (resourceId) {

				var elem = $("#buckets" + $scope.portlet.Id).closest(".portletContainerBody");
				$scope.bucketsContainerSize = elem.width() - 190;
				if (!$scope.filteredResource) {

					$scope.usersOrResources = $scope.usersOrResources.filter(function (el) {
						return el.ResourceId === resourceId;
					});
					$scope.filteredResource = true;
					$scope.resourceFilter = resourceId;

					$scope.recalculateBuckets();
				} else {
					$scope.filteredResource = false;
					$scope.resourceFilter = null;
					$scope.usersOrResources = $scope.originalBuckets;
					$scope.recalculateBuckets();
				}
			};

			$scope.calculateBucketSize = function (width, height, noOfBuckets, noOfResources) {
				//legend
				width -= 130;
				var padding = noOfBuckets * 10;
				var bucketsSpace = width - padding;
				var bucketWidth = bucketsSpace / noOfBuckets;

				//min width
				if (bucketWidth < 10)
					bucketWidth = 10;

				$scope.bucketWidth = bucketWidth;
				//toolbar
				height -= 150;
				//padding
				height -= (noOfResources * 10);
				var bucketHeight = height / noOfResources;
				//min height
				if (bucketHeight < $scope.configurationModel.ScopeConfiguration.BucketMinHeight)
					bucketHeight = $scope.configurationModel.ScopeConfiguration.BucketMinHeight;
				$scope.bucketHeight = bucketHeight;
			};
			$scope.recalculateBuckets = function () {
				var noOfBuckets = $scope.usersOrResources[0].BucketsAndTasks.length;
				var elem = $("#buckets" + $scope.portlet.Id).closest(".portletContainerBody");
				$scope.bucketsContainerSize = elem.width() - 170;
				$scope.calculateBucketSize($scope.bucketsContainerSize, elem.height(), noOfBuckets, $scope.usersOrResources.length);
				$scope.prepareDataForUsersAndResources($scope.usersOrResources);
			};

			$scope.extendScope = function () {
				$scope.DataModel.ScopeExtension += 1;
				$scope.getData();
			};
			$scope.decreaseScope = function () {
				$scope.DataModel.ScopeExtension -= 1;
				$scope.getData();
			};

			$scope.changeScope = function (direction) {

				$scope.resetBreadcrumbs();

				var defaultMode = $scope.configurationModel.ScopeConfiguration.DefaultMode;
				var signVariable = -1;
				var filter = Date.now();

				if ($scope.DataModel.BasicFilter)
					filter = $scope.DataModel.BasicFilter;

				var next = new Date(filter);

				//            if (direction === 'up') {

				//                if (defaultMode === 'SingleDay') {
				//                    next.setDate(next.getDate() + (1 * signVariable));
				//                    $scope.getData(next);
				//                }
				//            }
				//else
				if (direction === 'next') {
					signVariable = 1;
				}

				if (defaultMode === 'SingleDay' || defaultMode === 'Days') {
					next.setDate(next.getDate() + (1 * signVariable));
					$scope.getData(next);
				}
				else if (defaultMode === 'SingleWeek' || defaultMode === 'Weeks') {
					next.setDate(next.getDate() + (7 * signVariable));
					$scope.getData(next);
				}
				else if (defaultMode === 'Months') {
					next.setMonth(next.getMonth() + (1 * signVariable));
					$scope.getData(next);
				}
			}

			$scope.filterMonth = function (monthIndex, monthDescription) {
				$scope.configurationModel.ScopeConfiguration.DefaultMode = 'Weeks';
				$scope.getData(monthIndex);

				$scope.addBreadcrumb('month', monthIndex, monthDescription);
			};

			$scope.filterWeek = function (weekIndex, weekDescription) {
				$scope.configurationModel.ScopeConfiguration.DefaultMode = 'SingleWeek';
				$scope.getData(weekIndex);

				$scope.addBreadcrumb('week', weekIndex, weekDescription);
			};
			$scope.filterDay = function (dayIndex, dayDescription) {
				$scope.configurationModel.ScopeConfiguration.DefaultMode = 'SingleDay';
				$scope.getData(dayIndex);

				$scope.addBreadcrumb('day', dayIndex, dayDescription);
			};

			$scope.addBreadcrumb = function (type, linkText, title) {
				var newBreadcrumbs = angular.copy($scope.breadcrumbs);

				_.forEach(newBreadcrumbs, function (breadcrumb) {
					breadcrumb.Active = false;
				});
				$scope.breadcrumbs = newBreadcrumbs;

				var newItem = {
					Type: type,
					LinkText: linkText,
					Active: true,
					Title: title
				};
				$scope.breadcrumbs.push(newItem);
			};

			$scope.goToBreadcrumb = function (breadcrumb) {

				if (breadcrumb.Type === 'month') {
					$scope.breadcrumbs.splice(-1, 2);
					$scope.breadcrumbs.slice(-1)[0].Active = true;
					$scope.configurationModel.ScopeConfiguration.DefaultMode = 'Weeks';
					$scope.getData(breadcrumb.LinkText);
				}
				if (breadcrumb.Type === 'week') {
					$scope.breadcrumbs.splice(-1, 1);
					$scope.breadcrumbs.slice(-1)[0].Active = true;
					$scope.configurationModel.ScopeConfiguration.DefaultMode = 'SingleWeek';
					$scope.getData(breadcrumb.LinkText);
				}
			};


			$scope.switchMode = function (newMode) {
				$scope.resetBreadcrumbs();
				$scope.configurationModel.ScopeConfiguration.DefaultMode = newMode;
				$scope.DataModel.BasicFilter = null;
				$scope.DataModel.ScopeExtension = 0;
				$scope.selectedTask = null;
				$scope.getData();
			};

			$scope.isSelected = function (mode) {
				return $scope.configurationModel.ScopeConfiguration.DefaultMode === mode;
			};

			$scope.getData = function (filter) {

				$scope.controllingModel.DisplayLoadingBar = true;
				$scope.usersOrResources = [];
				if (filter)
					$scope.DataModel.BasicFilter = filter;

				$scope.DataModel.CurrentUiCulture = kendo.culture().name;
				$scope.DataModel.DataSourceType = $scope.portlet.DataSourceType;
				$scope.DataModel.PortletId = $scope.portlet.Id;
				$scope.DataModel.BucketsMode = $scope.configurationModel.ScopeConfiguration.DefaultMode;;

				$scope.DataModel.TasksQueryId = $scope.portlet.Queries[0] ? $scope.portlet.Queries[0].Id : null;
				$scope.DataModel.ResourcesQueryId = $scope.portlet.Queries[1] ? $scope.portlet.Queries[1].Id : null;
				$scope.DataModel.AssignmentsQueryId = $scope.portlet.Queries[2] ? $scope.portlet.Queries[2].Id : null;

				$scope.DataModel.TasksWorkflowId = $scope.portlet.Workflows[0] ? $scope.portlet.Workflows[0].WorkflowId : null;
				$scope.DataModel.ResourcesWorkflowId = $scope.portlet.Workflows[1] ? $scope.portlet.Workflows[1].WorkflowId : null;
				$scope.DataModel.AssignmentsWorkflowId = $scope.portlet.Workflows[2] ? $scope.portlet.Workflows[2].WorkflowId : null;

				$scope.DataModel.TasksOutputVariable = $scope.portlet.Workflows[0] ? $scope.portlet.Workflows[0].OutputVariable : null;
				$scope.DataModel.ResourcesOutputVariable = $scope.portlet.Workflows[1] ? $scope.portlet.Workflows[1].OutputVariable : null;
				$scope.DataModel.AssignmentsOutputVariable = $scope.portlet.Workflows[2] ? $scope.portlet.Workflows[2].OutputVariable : null;

				resourceBucketsService.getResources($scope.DataModel, $scope.portlet.Id).then(function (result) {
					$scope.usersOrResources = result.data;
					$scope.originalBuckets = result.data;

					if ($scope.resourceFilter) {
						$scope.filteredResource = false;
						$scope.filterResource($scope.resourceFilter);
						$scope.prepareDataForUsersAndResources($scope.usersOrResources);
					} else {
						if ($scope.usersOrResources.length > 0) {
							$scope.recalculateBuckets();
							$scope.prepareDataForUsersAndResources($scope.usersOrResources);
						}
					}
				}).catch(angular.noop).finally(function () {
					$scope.DataModel.RefreshWorkflowCache = false;
					$scope.controllingModel.DisplayLoadingBar = false;
				});
			};

			$scope.enableScopeExtensions = function () {
				return $scope.configurationModel.ScopeConfiguration.DefaultMode !== 'SingleDay' && $scope.configurationModel.ScopeConfiguration.DefaultMode !== 'SingleWeek';
			}

			$scope.canGoUp = function () {
				return false;
				// return $scope.configurationModel.ScopeConfiguration.DefaultMode === 'SingleDay' && $scope.configurationModel.ScopeConfiguration.DefaultMode === 'SingleWeek';
			};

			$scope.isBreadcrumbActive = function (breadcrumb) {
				return breadcrumb.Active;
			}

			$scope.resetBreadcrumbs = function () {
				$scope.breadcrumbs = [];
			};

			$scope.initializeBreadcrumbs = function () {
				$scope.breadcrumbs = [];
			};

			$scope.initialize = function () {

				$scope.initializeBreadcrumbs();

				$scope.dragDropData = {
					movedElementResourceId: null,
					movedElementId: null
				};
				$scope.DataModel = {};
				$scope.DataModel.ScopeExtension = 0;
				$scope.DataModel.RefreshWorkflowCache = true;
				$scope.resourceBucketsService = resourceBucketsService;
				$scope.imageService = imageService;
				$scope.variableService = variableService;
				$scope.translationFlowClientService = translationFlowClientService;
				$scope.editorService = editorService;
				$scope.translationsService = translationsService;
				$scope.selectedTask = null;
				$scope.filteredResource = false;
				$scope.resourceFilter = null;
				$scope.controllingModel = resourceBucketsConfigurationService.getControllingModel();
				$scope.configurationModel = pluginConfigurationService.getConfigurationModel($scope.portlet.Configuration, "ResourceBuckets");
				$scope.defaultTaskColor = $scope.configurationModel.ColorRulesConfiguration.DefaultColor;
				$scope.orderedColorRulesBackground = _.sortBy($scope.configurationModel.ColorRulesConfiguration.ColorRules, function (rule) {
					return rule.Position;
				});
				$scope.orderedIconRules = _.sortBy($scope.configurationModel.IconsConfiguration.Icons, function (icon) {
					return icon.Position;
				});

				dragulaService.options($scope, 'bucket', {
					revertOnSpill: true,
					copy: true
				});

				$scope.getData();
			};

			$scope.initialize();
		}
	]);
