app.controller("OperationAndInputExecutionController",
	["$scope", "$rootScope", "$uibModal", 'operationAndInputFlowService',
		function ($scope, $rootScope, $uibModal, operationAndInputFlowService
		) {

			$scope.runFlow = function (flowId, isUserAction, data, operationData) {

				$scope.controllingModel.DisplayLoadingBar = true;

				var taskData = {};
				var operationInputData = {};

				if (data)
					taskData = data;
				if (operationData)
					operationInputData = operationData;

				if (isUserAction) {
				    $scope.operationAndInputFlowService.runFlowUserAction($scope, flowId, taskData, operationInputData,
						function () {
							$scope.controllingModel.DisplayLoadingBar = false;
							$scope.$broadcast("onWorkflowExecutionSuccess", { PortletId: $scope.portlet.Id });
							$scope.refresh();
						}
					);
				}
				else {
					var objToSend = {
						workflowIdentifier: flowId,
						dataObject: taskData,
						operationInputData: operationInputData,
						onSuccessAction: function () {
							$scope.controllingModel.DisplayLoadingBar = false;
							$scope.refresh();
							$scope.$broadcast("onWorkflowExecutionSuccess", { PortletId: $scope.portlet.Id });
						},
						onErrorAction: function () {
							$scope.controllingModel.DisplayLoadingBar = false;
						}
					};
					$scope.operationAndInputFlowService.callFlowWithTableAndOperation(objToSend);
				}
			};

			$scope.initialize = function () {
				$scope.operationAndInputFlowService = operationAndInputFlowService;
			};

			$scope.initialize();
		}
	]);