app.service('mapService', ['basePluginService', '$q', 'dataEvalService', 'translationsService', 'imageService', '$sce',
	function (basePluginService, $q, dataEvalService, translationsService, imageService, $sce) {
		angular.extend(this, basePluginService);

		var service = {};

			service.getTextWidth = function (text, font) {
			// re-use canvas object for better performance
		
			var canvas = service.getTextWidth.canvas || (service.getTextWidth.canvas = document.createElement("canvas"));
			var context = canvas.getContext("2d");
			context.font = font;
			var metrics = context.measureText(text);

			return metrics.width;
		}

        /**
         * 
         * @param {} text 
         * @param {} item 
         * @param {} tooltipConfiguration 
         * @returns {} 
         */
		service.parseVariable = function (text, item, tooltipConfiguration) {
			if (!text) {
				return "";
			}
			var variables = text.match(/\{(.*?)\}/g);
			if (!variables) {
				return text;
			}
			variables.forEach(function (v) {
				var variableName = v.replace("{", "").replace("}", "");
				var variableValue = item[variableName];

				if (tooltipConfiguration) {

					var field = tooltipConfiguration.Fields.filter(function (f) {
						return f.DatabaseColumn === variableName;
					})[0];

					var editor = field ? field.Editor : null;

					switch (editor) {
						case "Date":
							variableValue = service.parseTextToCurrentKendoCulture(variableValue, "d");
							break;
						case "Time":
							variableValue = service.parseTextToCurrentKendoCulture(variableValue, "T");
							break;
						case "DateTime":
							variableValue = service.parseTextToCurrentKendoCulture(variableValue, "G");
							break;
						default:
							break;
					}
				}

				text = text.replace("{" + variableName + "}", variableValue == null ? "" : variableValue);
			});
			return text;
		}

        /**
         * 
         * @param {} text 
         * @param {} pattern 
         * @returns {} 
         */
		service.parseTextToCurrentKendoCulture = function (text, pattern) {
			return window.kendo.toString(window.kendo.parseDate(text), window.kendo.culture().calendar.patterns[pattern]);
		}


        /**
         * Evaluate rules and and calculate color
         * @param {} item 
         * @param {} rules 
         * @returns {} 
         */
		service.calculateColor = function (item, rules, defaultColor, colorField) {
			var evaluatedRules = rules.filter(function (rule) {
				var result = dataEvalService.evaluateExpression(
					dataEvalService.createNewDataSet(item.Data),
					'{' + rule.FirstValue + '}',
					rule.Func,
					rule.SecondValue);
				return result;
			});

			if (colorField)
				return evaluatedRules.length ? evaluatedRules[0][colorField] : defaultColor;
			return evaluatedRules.length ? evaluatedRules[0].Colour : defaultColor;
		}

        /** 
         * Check whether custom flow button should be disabled
         * @param {} configurationModel 
         * @param {} flowConfigId 
         * @param {} selectedTaskModel 
         * @returns {}  
         */
		service.isCustomFlowDisabled = function (configurationModel, flowConfigId, selectedMarker) {

			var flowConfig = configurationModel.FlowConfiguration.CustomFlows.filter(function (singleConfig) {
				return singleConfig.CustomFlow.Id == flowConfigId;
			})[0];

			if (flowConfig && flowConfig.AlwaysEnabled) {
				return false;
			}
			if (selectedMarker) {
				return !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet(selectedMarker.data),
					'{' + flowConfig.LeftValue + '}',
					flowConfig.Func,
					flowConfig.RightValue);
			};
			return true;
		};

        /**
         * Calculate icon
         * @param {} dataItem 
         * @param {} rules 
         * @param {} defaultIcon 
         * @returns {} 
         */
		service.calculateIcon = function (item, rules, defaultIcon, iconSize) {

			var evaluatedRules = rules.filter(function (rule) {
				var result = dataEvalService.evaluateExpression(
					dataEvalService.createNewDataSet(item.Data),
					'{' + rule.FirstValue + '}',
					rule.Func,
					rule.SecondValue);
				return result;
			});

			if (evaluatedRules.length) {
				return imageService.getImageInSize(evaluatedRules[0].Icon, iconSize);
			} else {
				return defaultIcon
					? imageService.getImageInSize(defaultIcon, iconSize)
					: null;

			}

		};

		service.calculateMarkersInShape = function (item, rules) {
			var evaluatedRules = rules.filter(function (rule) {
				var result = dataEvalService.evaluateExpression(
					dataEvalService.createNewDataSet(item.Data),
					'{' + rule.FirstValue + '}',
					rule.Func,
					rule.SecondValue);
				return result;
			});
			return evaluatedRules.length ? evaluatedRules[0] : null;
		}

		service.getPaths = function (responseData, configurationModel) {

			if (configurationModel.PathsConfiguration.length) {
				var paths = responseData.Markers.map(function (item, itemIndex, itemsArray) {

					var result = service.calculateMarkersInShape(item,
						_.sortBy(configurationModel.PathsConfiguration, function (item) {
							return item.Position;
						}));
					var tmpPath = null;
					if (result) {
						tmpPath = {
							color: result.Colour,
							weight: 3,
							type: result.Shape,
							lat: item.Latitude,
							lng: item.Longitude,
							ruleNumber: result.Position,
							message: result.PopupMessage
								? service.parseVariable(
									result.PopupMessage,
									item.Data,
									configurationModel.TooltipConfiguration
								)
								: null
						};
					}
					return tmpPath;
				});

				paths = _.chain(paths).filter(function (item) {
					return item;
				}).groupBy(function (p) {
					return p.ruleNumber;
				}).map(function (v) {
					return {
						color: v[0].color,
						weight: v[0].weight,
						type: v[0].type,
						message: v[0].message,
						latlngs: v.map(function (coord) {
							return { lat: coord.lat, lng: coord.lng };
						})
					}
				}).value();

				return paths;
			}

			return null;
		}

	    service.getLegend = function(configurationModel) {

	        var data = [];

	        if (configurationModel.IconsConfiguration.ShowOnLegend) {
	            data.push({
	                FirstValue: null,
	                Func: null,
	                Icon: configurationModel.IconsConfiguration.DefaultIcon,
	                Position: 0,
	                SecondValue: null,
	                ShowOnLegend: configurationModel.IconsConfiguration.ShowOnLegend //shoudl be true
	            });
	        }

	        data.push.apply(data, configurationModel.IconsConfiguration.Icons.filter(function(ic) {
	            return ic.ShowOnLegend;
	        }));

	        return _.sortBy(data,
	            function(item) {
	                return item.Positon;
	            });
	    };

        /**
         * 
         * @param {} responseData 
         * @param {} configurationModel 
         * @returns {} 
         */
		service.getMapData = function (responseData, configurationModel) {

			var isoDatePattern = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g;

		    var markers = responseData.Markers.map(function(item, itemIndex, itemsArray) {

		        var tmpMarker = {
		            id: item.Id,
		            lat: item.Latitude,
		            lng: item.Longitude,
		            focus: true,
		            draggable: true,
		            data: item.Data
		        };

		        tmpMarker.icon = {};
		        var label = '';

		        var iconUrl = service.calculateIcon(item,
		            _.sortBy(configurationModel.IconsConfiguration.Icons,
		                function(item) {
		                    return item.Positon;
		                }),
		            configurationModel.IconsConfiguration.DefaultIcon,
		            16);

		        if (configurationModel.TooltipConfiguration.Label) {
		            label = service.parseVariable(
		                configurationModel.TooltipConfiguration.Label,
		                item.Data,
		                configurationModel.TooltipConfiguration
		            );
		        }
		        var markerColor = service.calculateColor(item,
		            configurationModel.ColorRulesConfiguration.ColorRules,
		            configurationModel.ColorRulesConfiguration.DefaultColor);


		        var textWidth = service.getTextWidth(label, '14px Roboto, sans-serif');

                //7 px is a offset from left side of the label to pin center (with current font size values of course)
		        var leftOffset = 7 - (textWidth / 2);
		        var markerHtmlMarkup = 
		                '<i style="color:' + markerColor +'; font-family: markers !important;" class="marker-icon icon marker-icon-locator-round" aria-hidden="true">' +
		                    '<img src="' + iconUrl +'"/>' +
		                '</i>' +
		                '<div style="width:' + textWidth + 'px; position: absolute; top: 12px; left:' + leftOffset + 'px;" class="marker-label">' +
		                    label +
		                '</div>';
		        tmpMarker.icon = {
		            type: 'div',
		            html: markerHtmlMarkup
		        };

		        if (configurationModel.TooltipConfiguration.Tooltip) {

		            tmpMarker.tooltip = service.parseVariable(
		                translationsService.get(
		                    configurationModel.TooltipConfiguration.Code + '-Tooltip',
		                    configurationModel.TooltipConfiguration.Tooltip
		                ),
		                item.Data,
		                configurationModel.TooltipConfiguration
		            );
		        }

		        //parse dates to correct culture format
		        Object.keys(item).forEach(function(key) {
		            if (_.isString(item[key])) {
		                if (item[key].match(isoDatePattern)
		                ) { //do not use "regex.test" here as in some cases (when two fields are date in object) it doesn't work!
		                    item[key] = window.kendo.toString(window.kendo.parseDate(item[key]),
		                        window.kendo.culture().calendar.patterns.d);
		                }
		            }
		        });

		        return tmpMarker;

		    });
			service.getPaths(responseData, configurationModel);
			return markers;
		}


        /**
         * 
         * @param {} paginationModel 
         * @param {} portletId 
         * @returns {} 
         */
		service.getMarkers = function (paginationModel, portletId) {
			return basePluginService.backend.post("map", paginationModel, portletId);
		};

		service.getOverlays = function (paginationModel, portletId) {
			return basePluginService.backend.post("overlays", paginationModel, portletId);
		};



		return service;

	}]);