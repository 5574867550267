app.factory('FlowApplicationTasksConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.colorRulesBackground = $.parseJSON(propertyService.getPropertyValue(properties, "colorRulesBackground", "[]"));
      
        /* Flows START */
        configurationModel.WorkFlowModel = {};
        configurationModel.WorkFlowModel.DblClickUserActions = propertyService.getBoolPropertyValue(properties, "dblClickUserActions");

      var dblClickWorkflowJson = propertyService.getPropertyValue(properties, "dblClickWorkflow");

      var dblClickWorkflow = dblClickWorkflowJson ? $.parseJSON(dblClickWorkflowJson) : null;
        configurationModel.WorkFlowModel.DblClickWorkflow = dblClickWorkflow;
 
       configurationModel.WorkFlowModel.FlowConfigurations = $.parseJSON(propertyService.getPropertyValue(properties, "flowConfigurations", "[]"));

        /* Flows END */

        configurationModel.Mappers = $.parseJSON(propertyService.getPropertyValue(properties, "mappers", "[]"));
        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.Resizable = propertyService.getBoolPropertyValueAllowEmpty(properties, "resizable", true);
        configurationModel.Reorderable = propertyService.getBoolPropertyValueAllowEmpty(properties, "reorderable", true);
        configurationModel.MultiSelect = propertyService.getBoolPropertyValue(properties, "multiSelect");
      //  configurationModel.Exportable = propertyService.getBoolPropertyValue(properties, "exportable");
        configurationModel.IgnoreExecutionCheck = propertyService.getBoolPropertyValue(properties, "ignoreExecutionCheck");
        
        var colWidth = propertyService.getPropertyValueAllowEmpty(properties, "DefaultColumnWidth", "150");

        if (colWidth) {
            configurationModel.DefaultColumnWidth = parseInt(colWidth);
        }

        configurationModel.Groupable = propertyService.getBoolPropertyValueAllowEmpty(properties, "groupable", false);
        configurationModel.GroupingModel = {};
        configurationModel.GroupingModel.GroupBy = propertyService.getPropertyValue(properties, "groupBy", "");

        configurationModel.DefaultSortingModel = {};
        configurationModel.DefaultSortingModel.OrderBy = propertyService.getPropertyValue(properties, "DefaultOrderBy", undefined);
        configurationModel.DefaultSortingModel.Direction = propertyService.getPropertyValue(properties, "DefaultOrderByDirection", 'asc');

        configurationModel.CalculatedProcessIdentityFilter =propertyService.getPropertyValue(properties, "CalculatedProcessIdentityFilter", undefined);
              

        configurationService.assignIds(configurationModel.Mappers);
        configurationService.assignIds(configurationModel.WorkFlowModel.FlowConfigurations);

        configurationModel.EnableOperations = propertyService.getBoolPropertyValueAllowEmpty(properties, "enableOperations", true);
        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }
    service.saveProperties = function (properties, configurationModel, operation) {

        configurationService.saveProperties(properties, configurationModel);

        if (configurationModel.Mappers && operation === "copy") {
            configurationService.assignCodes(configurationModel.Mappers);
        }

        if (configurationModel.Mappers) {
            propertyService.setProperty(properties, "mappers", JSON.stringify(configurationModel.Mappers));
        }

        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());

        if (configurationModel.DefaultColumnWidth) {
            propertyService.setProperty(properties, "DefaultColumnWidth", configurationModel.DefaultColumnWidth.toString());
        } else {
            propertyService.setProperty(properties, "DefaultColumnWidth", "");
        }
        propertyService.setProperty(properties, "CalculatedProcessIdentityFilter", configurationModel.CalculatedProcessIdentityFilter.toString());
             

        if (configurationModel.WorkFlowModel.DblClickWorkflow) {
           propertyService.setPropertyWithRemoveOnEmpty(properties, "dblClickWorkflow",
                        JSON.stringify(configurationModel.WorkFlowModel.DblClickWorkflow));
        }
        if (configurationModel.WorkFlowModel.FlowConfigurations && operation === "copy") {
           configurationService.assignCodes(configurationModel.WorkFlowModel.FlowConfigurations);
       }
       if (configurationModel.WorkFlowModel.FlowConfigurations) {
           propertyService.setProperty(properties, "flowConfigurations", JSON.stringify(configurationModel.WorkFlowModel.FlowConfigurations));
        }

        /* Flows END*/
        propertyService.setBoolProperty(properties, "enableOperations", configurationModel.EnableOperations);
        propertyService.setBoolProperty(properties, "groupable", configurationModel.Groupable);
        propertyService.setBoolProperty(properties, "resizable", configurationModel.Resizable);
        propertyService.setBoolProperty(properties, "reorderable", configurationModel.Reorderable);
        propertyService.setBoolProperty(properties, "multiSelect", configurationModel.MultiSelect);
        //propertyService.setBoolProperty(properties, "exportable", configurationModel.Exportable);
        propertyService.setBoolProperty(properties, "ignoreExecutionCheck", configurationModel.IgnoreExecutionCheck);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "groupBy", configurationModel.GroupingModel.GroupBy);
        propertyService.setBoolProperty(properties, "dblClickUserActions", configurationModel.WorkFlowModel.DblClickUserActions);
        
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderBy", configurationModel.DefaultSortingModel.OrderBy);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderByDirection", configurationModel.DefaultSortingModel.Direction);
        
        if (configurationModel.colorRulesBackground) {
            propertyService.setProperty(properties, "colorRulesBackground", JSON.stringify(configurationModel.colorRulesBackground));
        }


    }

    return service;
}]);