app.service('kanbanService', ['basePluginService', '$q', 'dataEvalService', 'translationsService', 'imageService', '$sce',
    function (basePluginService, $q, dataEvalService, translationsService, imageService, $sce) {
        angular.extend(this, basePluginService);

        var service = {};

        /**
         * Get tasks from backend
         * @param {} paginationModel 
         * @param {} portletId 
         * @returns {} 
         */
        service.getTasks = function (paginationModel, portletId) {

            return basePluginService.backend.post("kanban", paginationModel, portletId);
        };

        service.getStatuses = function (paginationModel) {

            return basePluginService.backend.post("queryExecutionHeader", paginationModel);
        };

        /**
         * Parse configuration variable from string, eg "This is a {VariableName}" => "This is a " + item[VariableName]
         * @param {} text 
         * @param {} item 
         * @returns {} 
         */
        //service.parseVariable = (text, item) => {
        service.parseVariable = function (text, item, tooltipConfiguration) {
            if (!text) {
                return "";
            }
            var variables = text.match(/\{(.*?)\}/g);
            if (!variables) {
                return text;
            }
            variables.forEach(function(v) {
                var variableName = v.replace("{", "").replace("}", "");
                var variableValue = item[variableName];

                if (tooltipConfiguration) {

                    var field = tooltipConfiguration.Fields.filter(function (f) {
                        return f.DatabaseColumn === variableName;
                    })[0];

                    var editor = field ? field.Editor : null;

                    switch (editor) {
                    case "Date":
                        variableValue = service.parseTextToCurrentKendoCulture(variableValue, "d");
                        break;
                    case "Time":
                        variableValue = service.parseTextToCurrentKendoCulture(variableValue, "T");
                        break;
                    case "DateTime":
                        variableValue = service.parseTextToCurrentKendoCulture(variableValue, "G");
                        break;
                    default:
                        break;
                    }
                }
                
                text = text.replace("{" + variableName + "}", variableValue == null ? "" : variableValue);
            });
            return text;
        }

        /**
         * 
         * @param {} text 
         * @param {} pattern 
         * @returns {} 
         */      
        service.parseTextToCurrentKendoCulture = function(text, pattern) {
            return window.kendo.toString(window.kendo.parseDate(text), window.kendo.culture().calendar.patterns[pattern]);
        }

        /**
         * Parse single row from CardConfiguration properties
         * @param {} rowModel 
         * @param {} item 
         * @returns {} 
         */
        service.parseRow = function (rowModel, item) {
            var obj = {};
            obj.Label = service.parseVariable(rowModel.Label, item);
            obj.Text = service.parseVariable(rowModel.Text, item);
            return obj;
        };

        /**
         * 
         * @param {} groups 
         * @param {} columnsConfigurations 
         * @returns {} 
         */
        service.getMappedAndVisibleColumns = function (groups, columnsConfigurations, statusColumnName) {

            var visibleColumnsConfigurations = columnsConfigurations.filter(function (tcc) {
                var mappers = groups.filter(function (g) {
                    return g.StatusValue == tcc.value && tcc.Visible;
                });
                return mappers.length ? tcc : angular.noop();
            });

            var columns = visibleColumnsConfigurations.map(function(o) {

                var tmpGroup = _.find(groups,
                    function(b) {
                        return b.value == o.StatusValue;
                    });

                var group = {
                    aggregates: tmpGroup ? tmpGroup.aggregates : {},
                    field: tmpGroup ? tmpGroup.field : statusColumnName,
                    items: tmpGroup ? tmpGroup.items : [],
                    value: o.StatusValue
                };

                return group;
            });

            return columns;
        }

        /**
         * 
         * @param {} groups 
         * @param {} columnsConfigurations 
         * @returns {} 
         */
        service.getNotMappedColumns = function(groups, columnsConfigurations) {
            return groups.filter(function(g) {
                var mappers = columnsConfigurations.filter(function(c) {
                    return c.StatusValue == g.value;
                });
                return !mappers.length ? g : angular.noop();
            });
        }

        /**
         * Evaluate rules and and calculate color
         * @param {} item 
         * @param {} rules 
         * @returns {} 
         */
        service.calculateColor = function(item, rules, defaultColor) {
            var evaluatedRules = rules.filter(function(rule) {
                var result = dataEvalService.evaluateExpression(
                    dataEvalService.createNewDataSet(item),
                    '{' + rule.FirstValue + '}',
                    rule.Func,
                    rule.SecondValue);
                return result;
            });
			return evaluatedRules.length ? evaluatedRules[0].Colour : defaultColor;
        }

        /**
         * Check whether custom flow button should be disabled
         * @param {} configurationModel 
         * @param {} flowConfigId 
         * @param {} selectedTaskModel 
         * @returns {} 
         */
        service.isCustomFlowDisabled = function (configurationModel, flowConfigId, selectedTaskModel) {

            var flowConfig = configurationModel.FlowConfiguration.CustomFlows.filter(function (singleConfig) {
                return singleConfig.CustomFlow.Id == flowConfigId;
            })[0];

            if (flowConfig && flowConfig.AlwaysEnabled) {
                return false;
            }
            if (selectedTaskModel) {
                return !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet(selectedTaskModel),
                    '{' + flowConfig.LeftValue + '}',
                    flowConfig.Func,
                    flowConfig.RightValue);
            };
            return true;
        };

        /**
         * Calculate icon
         * @param {} dataItem 
         * @param {} rules 
         * @param {} defaultIcon 
         * @returns {} 
         */
        service.calculateIcon = function (item, rules, defaultIcon, iconSize) {

            var evaluatedRules = rules.filter(function (rule) {
                var result = dataEvalService.evaluateExpression(
                    dataEvalService.createNewDataSet(item),
                    '{' + rule.FirstValue + '}',
                    rule.Func,
                    rule.SecondValue);
                return result;
            });

            if (evaluatedRules.length) {
                return imageService.getImageInSize(evaluatedRules[0].Icon, iconSize);
            } else {
                return defaultIcon ? imageService.getImageInSize(defaultIcon, iconSize) : null;
            }

        };

        /**
         * Transform kanban endpoint response to kanban model using configurationModel
         * @param {} responseData 
         * @param {} configurationModel 
         * @returns {} 
         */
        service.getKanbanData = function (responseData, configurationModel) {

            var isoDatePattern = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g;

            var mappedAndVisibleColumns = service.getMappedAndVisibleColumns(responseData.Groups, configurationModel.ColumnsConfigurations, configurationModel.TasksDatasource.TaskStatusColumnName);
            var notMappedColumns = service.getNotMappedColumns(responseData.Groups, configurationModel.ColumnsConfigurations);
            var columnsToDisplay = mappedAndVisibleColumns.concat(notMappedColumns);

            var columns = columnsToDisplay.map(
                function (group, groupIndex, groupsArray) {

                    var tmpCol = {
                        status: group.value,
                        text: group.value,
                        visible: true,
                        maxCount: 10,
                        noMaxCountLimit: true,
                        color: "#337ab7", 
                        orderId: 99999,
                        items: []
                    };

                    tmpCol.items = group.items.map(function (item, itemIndex, itemsArray) {

                        var tmpItem = {};
                        tmpItem.id = item[configurationModel.TasksDatasource.TaskIdColumnName],
                        tmpItem[configurationModel.TasksDatasource.TaskOrderIdColumnName] = item[configurationModel.TasksDatasource.TaskOrderIdColumnName],
                        tmpItem.position = itemIndex + 1;
                        tmpItem.data = item;

                        //Apply card configuration:

                        //first tooltip before date parsing
                        if (configurationModel.CardConfiguration.TooltipConfiguration.Tooltip) {
                            tmpItem.tooltip = $sce.trustAsHtml(service.parseVariable(
                                translationsService.get(
                                    configurationModel.CardConfiguration.TooltipConfiguration.Code + '-Tooltip',
                                    configurationModel.CardConfiguration.TooltipConfiguration.Tooltip),
                                item,
                                configurationModel.CardConfiguration.TooltipConfiguration));
                        }
                        //parse dates to correct culture format
                        Object.keys(item).forEach(function(key) {
                            if (_.isString(item[key])) {
                                if (item[key].match(isoDatePattern)) { //do not use "regex.test" here as in some cases (when two fields are date in object) it doesn't work!
                                    item[key] = window.kendo.toString(window.kendo.parseDate(item[key]), window.kendo.culture().calendar.patterns.d);
                                }
                            }
                        });
                        
                        if (configurationModel.CardConfiguration.DataRowHeader) {
                            tmpItem.header =
                                service.parseRow(
                                    {
                                        Label: translationsService.get(configurationModel.CardConfiguration.DataRowHeader.Code + '-Label', configurationModel.CardConfiguration.DataRowHeader.Label),
                                        Text: configurationModel.CardConfiguration.DataRowHeader.Text
                                    },
                                    item);
                        }
                        if (configurationModel.CardConfiguration.DataRowOne) {
                            tmpItem.row1 =
                                service.parseRow(
                                    {
                                        Label: translationsService.get(configurationModel.CardConfiguration.DataRowOne.Code + '-Label' , configurationModel.CardConfiguration.DataRowOne.Label),
                                        Text: configurationModel.CardConfiguration.DataRowOne.Text
                                    },
                                    item);
                        }
                        if (configurationModel.CardConfiguration.DataRowTwo) {
                            tmpItem.row2 =
                                service.parseRow(
                                    {
                                        Label: translationsService.get(configurationModel.CardConfiguration.DataRowTwo.Code + '-Label', configurationModel.CardConfiguration.DataRowTwo.Label),
                                        Text: configurationModel.CardConfiguration.DataRowTwo.Text
                                    },
                                    item);
                        }
                        if (configurationModel.CardConfiguration.DataRowThree) {
                            tmpItem.row3 =
                                service.parseRow(
                                    {
                                        Label: translationsService.get(configurationModel.CardConfiguration.DataRowThree.Code + '-Label', configurationModel.CardConfiguration.DataRowThree.Label),
                                        Text: configurationModel.CardConfiguration.DataRowThree.Text
                                    },
                                    item);
                        }
                        if (configurationModel.CardConfiguration.DataRowFour) {
                            tmpItem.row4 =
                                service.parseRow(
                                    {
                                        Label: translationsService.get(configurationModel.CardConfiguration.DataRowFour.Code + '-Label', configurationModel.CardConfiguration.DataRowFour.Label),
                                        Text: configurationModel.CardConfiguration.DataRowFour.Text
                                    },
                                    item);
                        }

                        tmpItem.color = service.calculateColor(item,
                            _.sortBy(configurationModel.ColorRulesConfiguration.ColorRules, function(item) {
                                return item.Position;
                            }),
                            configurationModel.ColorRulesConfiguration.DefaultColor);

                        tmpItem.icon = service.calculateIcon(item,
                            _.sortBy(configurationModel.IconsConfiguration.Icons, function(item) {
                                return item.Position;
                            }),
                            configurationModel.IconsConfiguration.DefaultIcon, 28);

                       
                        return tmpItem;
                    });

                    return tmpCol;
                });

            //apply columns configuration
            columns.map(function (column) {

                var statusColumn = configurationModel.ColumnsConfigurations.filter(function (cc) {
                    return cc.StatusValue == column.status; /* ONLY values compared, without types! */
                })[0];

                column.color = statusColumn ? statusColumn.Color : column.color;
                column.text = statusColumn? translationsService.get(statusColumn.Code + '-Text', statusColumn.Text) : column.text,
                column.visible = statusColumn ? statusColumn.Visible : column.visible;
                column.maxCount = statusColumn ? statusColumn.MaxCount : column.maxCount;
                column.noMaxCountLimit = statusColumn ? statusColumn.NoMaxCountLimit : column.noMaxCountLimit;
                column.orderId = statusColumn ? statusColumn.OrderId : column.orderId;

                column.items = _.orderBy(column.items, [configurationModel.TasksDatasource.TaskOrderIdColumnName], [configurationModel.TasksDatasource.TaskOrderSortDirection.value]);

                return column;
            });
            return _.orderBy(columns, "orderId", "asc"); //always
        };

        return service;

    }]);